import RTableLayout from "../../../layout/RTableLayout";
import {CFlex, CMargin, DText, Flex, Row} from "../../../../../styles/CommonStyles";
import {getNames} from "../../../../../core/utils/common";
import Avatar from "../../../../common/utils/Avatar";
import UserLink from "../../../../common/utils/UserLink";
import Operation from "../../../../common/utils/Operation";
import useAffiliateList from "../../../../../core/hooks/main/affiliates/useAffiliatesList";
import ShareBar from "../../../../main/reports/affiliates/ShareBar";
import Text from "../../../../../core/utils/Text";
import {LineBreak, RespIndex} from "../../../../../styles/main/MainCommonStyles";
import useGetTableIndex from "../../../../../core/hooks/layout/useGetTableIndex";
import ModalLayout from "../../../../layout/main/ModalLayout";
import BasicModal from "../../../../modals/BasicModal";


const RAffiliateListTable = ({ data }) => {

    const { data: affiliates } = data
    const { getTableIndex } = useGetTableIndex()

    const {
        hasWriteAccess,
        loading,
        onDeleteClicked,
        onDetailsClicked,
        onSubmitClicked,
        modal,
        onModalClose
    } = useAffiliateList()

    return (
        <RTableLayout
            data={data}
        >
            {affiliates?.data?.map((item, idx) => {

                const {
                    FULLNAME, NAMEAVATAR
                } = getNames(
                    item.user?.firstName,
                    item.user?.lastName
                )

                return (
                    <Row cs={'100%'} index={idx} key={item._id}>
                        <Flex fw>
                            <Flex width={'50%'} justify={'flex-start'}>
                                <Avatar
                                    name={NAMEAVATAR}
                                    avatar={item.user?.avatar}
                                />
                                <UserLink
                                    _id={item.userId}
                                    name={FULLNAME}
                                />
                            </Flex>
                            <Flex width={'50%'} justify={'flex-end'}>
                                <Operation
                                    onDeleteClicked={() => onDeleteClicked(item)}
                                    onDetailsClicked={() => onDetailsClicked(item)}
                                    deleteTooltip={'delete-affiliate'}
                                    hasWriteAccess={hasWriteAccess}
                                    hasDetails
                                    hasDelete
                                />
                            </Flex>
                        </Flex>
                        <CFlex fw>
                            <CMargin margin={'4px'} />
                            <Flex justify={'space-between'} fw>
                                <DText main fontSize={'ss'}>
                                    <Text tid={'affiliate-code'} /> :
                                </DText>
                                <DText primary fontSize={'ss'}>
                                    {item.code}
                                </DText>
                            </Flex>
                            <CMargin margin={'4px'} />
                            <Flex fw style={{ margin: '5px 0' }}>
                                <ShareBar
                                    affiliate={item}
                                />
                            </Flex>
                            <LineBreak />
                            <CMargin margin={'4px'} />
                            <Flex justify={'space-between'} fw>
                                <DText main fontSize={'ss'}>
                                    <Text tid={'use-count'} /> :
                                </DText>
                                <DText primary fontSize={'ss'}>
                                    {item.used}
                                </DText>
                            </Flex>
                            <CMargin margin={'4px'} />
                            <Flex justify={'space-between'} fw>
                                <DText main fontSize={'ss'}>
                                    <Text tid={'max-use'} /> :
                                </DText>
                                <DText primary fontSize={'ss'}>
                                    {item.maxUse}
                                </DText>
                            </Flex>
                            <CMargin margin={'4px'} />
                            <RespIndex>
                                {getTableIndex(idx)}
                            </RespIndex>
                        </CFlex>
                    </Row>
                )
            })}
            <ModalLayout
                open={modal.open}
                onClose={onModalClose}
                width={'580px'}
            >
                <BasicModal
                    head={'delete-affiliate-head'}
                    desc={'delete-affiliate-desc'}
                    _id={modal.affiliate?._id}
                    onClose={onModalClose}
                    onSubmit={onSubmitClicked}
                    loading={loading}
                />
            </ModalLayout>
        </RTableLayout>
    )
}


export default RAffiliateListTable
