import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {useState} from "react";
import {TABLET_SIZE} from "../../../core/constants/common";
import RContractTable from "../../../components/responsive/main/reports/contract/RContractTable";
import ContractTable from "../../../components/main/reports/contract/ContractTable";
import FilterLayout from "../../../components/layout/filter/FilterLayout";
import {CacheKeys, contractFilterOptions, contractSortOptions} from "../../../core/constants/filter";
import ListLayout from "../../../components/layout/main/ListLayout";
import {PageCard} from "../../../styles/CommonStyles";
import {useListWalletTransactions} from "../../../core/services/react-query/report/contract";



const WalletTransactions = () => {

    const { width } = useWindowSize()

    const [contracts, setContracts] = useState({loading: true, data: null})
    const onUserQuery = res => setContracts(res)

    const Component = width > TABLET_SIZE ? ContractTable : RContractTable

    return (
        <ListLayout>
            <PageCard>
                <FilterLayout
                    query={useListWalletTransactions}
                    options={contractFilterOptions}
                    onQuerySuccess={onUserQuery}
                    cache={CacheKeys.WALLET_TRANSACTIONS}
                    sortOptions={contractSortOptions}
                >
                    <Component data={contracts} />
                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}

export default WalletTransactions
