import {workspaceAttributes} from "../../../../core/constants/headers";
import TableLayout from "../../../layout/main/TableLayout";
import {Column, Relative, Row} from "../../../../styles/CommonStyles";
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex";
import BooleanIcon from "../../../common/utils/BooleanIcon";
import DateTime from "../../../common/utils/DateTime";
import Operation from "../../../common/utils/Operation";
import {useNavigate} from "react-router-dom";
import NameTag from "../../../common/utils/NameTag";
import UserTag from "../../../common/utils/UserTag";
import {TradeTypeBadge} from "../../../../styles/main/orders";
import {getTradeTypeColor} from "../../../../core/utils/theme";
import Text from "../../../../core/utils/Text";


const WorkspaceTable = ({ data }) => {

    const navigate = useNavigate()
    const { data: workspaces } = data
    const { headers, cs } = workspaceAttributes
    const { getTableIndex } = useGetTableIndex()

    const onDetailsClicked = (workspace) => navigate(`/reports/businesses/${workspace._id}`)

    return (
        <TableLayout
            headers={headers}
            cs={cs}
            data={data}
        >
            {workspaces?.data?.map((item,idx) => (
                <Relative key={item._id}>
                    <Row index={idx} cs={cs}>
                        <Column>
                            {getTableIndex(idx)}
                        </Column>
                        <Column>
                            <UserTag user={item.user} idx={idx} />
                        </Column>
                        <Column>
                            <NameTag name={item.name} />
                        </Column>
                        <Column>
                            {item.ip}
                        </Column>
                        <Column>
                            <TradeTypeBadge color={getTradeTypeColor(item.category)}>
                                <Text tid={item.category} />
                            </TradeTypeBadge>
                        </Column>
                        <Column center>
                            <BooleanIcon bool={item.isActive} />
                        </Column>
                        <Column center>
                            <DateTime dt={item.createdAt} />
                        </Column>
                    </Row>
                    <Column operation>
                        <Operation
                            onDetailsClicked={() => onDetailsClicked(item)}
                            hasDetails
                        />
                    </Column>
                </Relative>
            ))}
        </TableLayout>
    )
}


export default WorkspaceTable
