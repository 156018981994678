import RTableLayout from "../../../layout/RTableLayout";
import {CFlex, CMargin, DText, Flex, Row} from "../../../../../styles/CommonStyles";
import Operation from "../../../../common/utils/Operation";
import {useNavigate} from "react-router-dom";
import Text from "../../../../../core/utils/Text";
import BooleanIcon from "../../../../common/utils/BooleanIcon";
import UserTag from "../../../../common/utils/UserTag";
import {LineBreak} from "../../../../../styles/main/MainCommonStyles";
import {formatDate} from "../../../../../core/utils/common";
import {useMainContext} from "../../../../../core/contexts/main";


const RWorkspaceTable = ({data}) => {

    const { lang } = useMainContext()
    const navigate = useNavigate()
    const { data: workspaces } = data
    const onDetailsClicked = (workspace) => navigate(`/reports/workspace/${workspace._id}`)

    return (
        <RTableLayout
            data={data}
        >
            {workspaces?.data?.map((item, idx) => (
                <Row key={item._id} index={idx} cs={'100%'}>
                    <Flex justify={'flex-end'}>
                        <Operation
                            onDetailsClicked={() => onDetailsClicked(item)}
                            hasDetails
                        />
                    </Flex>
                    <UserTag user={item.user} idx={idx} />
                    <LineBreak />
                    <Flex fw justify={'space-between'}>
                        <DText fontSize={'ss'} main>
                            <Text tid={'name'} /> :
                        </DText>
                        <DText fontSize={'ss'} primary>
                            {item.name}
                        </DText>
                    </Flex>
                    <CMargin margin={'3px'} />
                    <Flex fw justify={'space-between'}>
                        <DText fontSize={'ss'} main>
                            <Text tid={'ip'} /> :
                        </DText>
                        <DText fontSize={'ss'} primary>
                            {item.ip}
                        </DText>
                    </Flex>
                    <CMargin margin={'3px'} />
                    <Flex fw justify={'space-between'}>
                        <DText fontSize={'ss'} main>
                            <Text tid={'category'} /> :
                        </DText>
                        <DText fontSize={'ss'} primary>
                            {item.category}
                        </DText>
                    </Flex>
                    <CMargin margin={'3px'} />
                    <Flex fw justify={'space-between'}>
                        <DText fontSize={'ss'} main>
                            <Text tid={'is-active'} /> :
                        </DText>
                        <DText fontSize={'ss'} primary>
                            <BooleanIcon bool={item.isActive} />
                        </DText>
                    </Flex>
                    <CMargin margin={'3px'} />
                    <Flex fw justify={'space-between'}>
                        <DText fontSize={'ss'} main>
                            <Text tid={'date'} /> :
                        </DText>
                        <DText fontSize={'ss'} primary>
                            { `${formatDate(item.createdAt, 'date', lang)}-${formatDate(item.createdAt, 'time', lang)}` }
                        </DText>
                    </Flex>
                </Row>
            ))}
        </RTableLayout>
    )
}


export default RWorkspaceTable
