
export const initialAcl = {
    user: {read: false, write: false},
    workspace: {read: false, write: false},
    workspaceWithdraw: {read: false, write: false},
    contract: {read: false, write: false},
    payment: {read: false, write: false},
    address: {read: false, write: false},
    wallet: {read: false, write: false},
    role: {read: false, write: false},
    settings: {read: false, write: false},
    affiliate: {read: false, write: false},
    trace: {read: false, write: false}
}

export const superAdmin = {
    user: {read: true, write: true},
    workspace: {read: true, write: true},
    workspaceWithdraw: {read: true, write: true},
    contract: {read: true, write: true},
    payment: {read: true, write: true},
    address: {read: true, write: true},
    wallet: {read: true, write: true},
    role: {read: true, write: true},
    settings: {read: true, write: true},
    affiliate: {read: true, write: true},
    trace: {read: true, write: true},
    multiSignature: { read: true, write: true }
}
