import {useParams} from "react-router-dom";
import { useGetWalletTransaction} from "../../../core/services/react-query/report/contract";
import {Flex} from "../../../styles/CommonStyles";
import ObjectView from "../../../components/common/utils/ObjectView";
import {useMainContext} from "../../../core/contexts/main";


const WalletTransactionDetails = () => {

    const params = useParams()
    const { data: contract } = useGetWalletTransaction(params.id)
    const { theme } = useMainContext()

    return (
        <Flex fw height={'100vh'}>
            <ObjectView obj={contract} theme={theme} />
        </Flex>
    )
}

export default WalletTransactionDetails
