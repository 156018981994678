import Input from "../../../../common/input/Input";
import {useState} from "react";
import {Background, CMargin, Decoration} from "../../../../../styles/CommonStyles";
import Text from "../../../../../core/utils/Text";
import ActionButton from "../../../../common/buttons/ActionButton";
import {useRecoverPayments} from "../../../../../core/services/react-query/setting";
import Select from "../../../../common/dropdown/Select";


const RecoverPayment = () => {

    const [transactionId, setTransactionId] = useState('')
    const [paymentId, setPaymentId] = useState('')
    const [status, setStatus] = useState('')

    const { mutate: recoverPayment, isLoading } = useRecoverPayments()
    const onAction = () => {
        let payload = {
            status,
            transactionId
        }
        recoverPayment({ payload, paymentId })
    }

    const STATUS = [
        'success', 'pending',
        'error', 'expired',
        'confirming', 'partiallyPaid',
        'refunded', 'adminRelation'
    ]

    return (
        <div className={'max-w-[380px] flex flex-col lg:mt-8 gap-3 dark:text-gray-100 text-slate-800 mb-10'}>
            <div className={'flex items-center'}>
                <Decoration />
                <Text tid={'recover-payment-note'} />
            </div>
            <Background bg={'mainBg'}>
                <Input
                    value={transactionId}
                    onInputChange={setTransactionId}
                    label={'transaction-id'}
                    height={'42px'}
                    size={'small'}
                />
            </Background>
            <Background bg={'mainBg'}>
                <Input
                    value={paymentId}
                    onInputChange={setPaymentId}
                    label={'payment-id'}
                    height={'42px'}
                    size={'small'}
                />
            </Background>
            <Select
                value={status}
                onValueChange={idx => setStatus(STATUS[idx])}
                options={STATUS}
                placeHolder={'status'}
                height={'42px'}
            />
            <CMargin margin={'4px'} />
            <ActionButton loading={isLoading} active={!!paymentId && !!transactionId} height={'42px'} onClick={onAction}>
                <Text tid={'submit'} />
            </ActionButton>
        </div>
    )
}

export default RecoverPayment
