import { useState } from "react"
import { useParams } from "react-router-dom"
import { TABLET_SIZE } from "../../../../../core/constants/common"
import { affiliateTraceFilterOptions, CacheKeys } from "../../../../../core/constants/filter"
import { useWindowSize } from "../../../../../core/hooks/common/useWindowSize"
import { useGetAffiliateReports } from "../../../../../core/services/react-query/report/trace"
import { PageCard } from "../../../../../styles/CommonStyles"
import FilterLayout from "../../../../layout/filter/FilterLayout"
import ListLayout from "../../../../layout/main/ListLayout"
import RTraceTable from "../../../../responsive/main/reports/trace/RTraceTable"
import TraceTable from "../../trace/tables/TraceTable"
import {useAclContext} from "../../../../../core/contexts/acl";



const AffilateReportList = () => {

    const {width} = useWindowSize()
    const params = useParams()

    const [traces, setTraces] = useState({data: null, loading: true})
    const onQuerySuccess = (res) => setTraces(res)

    const {permissions} = useAclContext()
    const hasDownload = permissions?.export?.write

    const Component = width > TABLET_SIZE ? TraceTable : RTraceTable

    return (
        <ListLayout>
            <PageCard>
                <FilterLayout
                    query={useGetAffiliateReports}
                    onQuerySuccess={onQuerySuccess}
                    options={affiliateTraceFilterOptions}
                    cache={CacheKeys.AFFILIATE_TRACE}
                    extra={{ 'cause.action': 'affiliate', currency: params.id }}
                    hasDownload={hasDownload}
                >
                    {width &&
                        <Component
                            data={traces}
                        />
                    }
                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}


export default AffilateReportList
