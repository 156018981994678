import {useQuery} from "react-query";
import {normalFetch} from "../../fetch-api/get";


export const useGetPaymentAnalytics = ({ businessId }) => {

    return useQuery(
        ['payment-analytics', businessId], () => normalFetch(`payments/admin/analytics?search=businessId&query=${businessId}`),
        {
            enabled: !!businessId,
            select: res => res?.data?.data
        }
    )
}
