import {useGetBlockedStatus, useSetBlockedStatus} from "../../../../../core/services/react-query/setting";
import Text from "../../../../../core/utils/Text";
import Toggle from "../../../../common/buttons/Toggle";
import {useEffect, useState} from "react";
import {MOBILE_SIZE} from "../../../../../core/constants/common";
import ABActionButton from "../ABActionButton";
import {Absolute} from "../../../../../styles/CommonStyles";
import {useWindowSize} from "../../../../../core/hooks/common/useWindowSize";
import {useUpdateAUser} from "../../../../../core/services/react-query/user";


const BlockedStatus = ({ isUser = false, user, refetch }) => {

    const { width } = useWindowSize()
    const { data: blockedStatus } = useGetBlockedStatus()
    const { mutate: setBlockedStatus, isLoading: blockedStatusLoading } = useSetBlockedStatus()
    const { mutate: updateAUser, isLoading: userUpdateLoading } = useUpdateAUser(refetch)
    const loading = blockedStatusLoading || userUpdateLoading

    const [detailsChanged, setDetailsChanged] = useState(false)
    const [info, setInfo] = useState({})
    useEffect(() => {
        if (isUser && !!user) setInfo(user.blocked || { login: false, deposit: false, withdraw: false })
        else if (!!blockedStatus) setInfo(blockedStatus)
    }, [blockedStatus, user, isUser])

    useEffect(() => {
        let isChanged = false
        const keys = Object.keys(info)
        for (const key of keys) {
            if (info[key] !== (isUser ? user?.blocked[key] : blockedStatus[key])) {
                isChanged = true
                break
            }
        }
        setDetailsChanged(isChanged)
    }, [info, blockedStatus])


    const onInputValueChange = (v, t) => {
        setInfo(state => ({...state, [t]: v}))
    }

    const onSubmitClicked = () => {
        if (loading) return
        const payload = {...info}
        if (isUser) {
            delete payload.register
            updateAUser({ data: {blocked: payload}, id: user?._id })
        }else
            setBlockedStatus(payload)
    }

    return (
        <>
            <div className={'grid grid-cols-4 gap-5 dark:text-gray-100 text-slate-800 max-w-[520px] mx-auto mt-8 relative'}>
                {Object.keys(info).map(item => {

                    if (isUser && item === 'register') return <></>
                    return (
                        <ToggleItem value={info[item]} onChange={v => onInputValueChange(v, item)} type={item} />
                    )
                })}

            </div>
            {width > MOBILE_SIZE ?
                <ABActionButton
                    show={detailsChanged}
                    onClick={onSubmitClicked}
                    loading={loading}
                />
                :
                <Absolute style={{ position: 'fixed' }} left={0} bottom={0} height={'60px'}>
                    <ABActionButton
                        show={detailsChanged}
                        onClick={onSubmitClicked}
                        loading={loading}
                    />
                </Absolute>
            }
        </>

    )
}

const ToggleItem = ({ value, onChange, type }) => {

    return (
        <div className={'flex items-center gap-4'}>
            <Text tid={type} />
            <Toggle
                active={value}
                onChange={() => onChange(!value)}
            />
        </div>
    )
}

export default BlockedStatus
