import {useQuery} from "react-query";
import {filterFetch} from "../../../fetch-api/get";
import {useMainContext} from "../../../../contexts/main";
import {makeQueryFilters} from "../../../../utils/query-maker";


const ADDRESS_KEYS = {
    FETCH_ADDRESSES: 'fetch-addresses'
}


const useGetAddresses = (filters, pagination, extra) => {

    const { lang } = useMainContext()
    const newFilters = {...filters, ...extra}
    const params = {...makeQueryFilters(newFilters, lang), ...pagination}

    return useQuery(
        ADDRESS_KEYS.FETCH_ADDRESSES, () => filterFetch(params, 'addresses/admin')
    )
}


export {
    useGetAddresses
}
