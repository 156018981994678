import MotionFade from "../../../common/utils/MotionFade"
import { Background, CFlex, CMargin, Decoration, DText, Flex } from "../../../../styles/CommonStyles"
import Input from "../../../common/input/Input"
import Text from "../../../../core/utils/Text"
import CheckBox from "../../../common/input/CheckBox";

/**
 * @param detail - { min, max, makerFeeFactor, takerFeeFactor, makerFeeMax, takerFeeMax }
 * @param type - { order - otc }
 * @param onDetailChange - { update detail }
 * @param currency - { currency of details }
 * @returns {JSX.Element}
 * @constructor
 */
const TransferUpdate = ({
    detail,
    type,
    onDetailChange,
    currency
}) => {


    return (
        <MotionFade>
            {detail &&
               <CFlex fw align='flex-start'>
                    <CMargin margin='4px' />
                   <Flex>
                       <DText main>
                           <Text tid={'is-active'} />:
                       </DText>
                       <Flex style={{ margin: '0 5px' }}>
                           <CheckBox
                               active={detail.isActive}
                               onClick={() => onDetailChange({
                                   type,
                                   key: 'isActive',
                                   isBoolean: true
                               })}
                           />
                       </Flex>
                   </Flex>
                   <CMargin margin='8px' />
                   <Flex fw wrap justify='space-between'>
                        <Background bg='detailBox'>
                            <Input
                                value={detail.min}
                                label={'transfer-min'}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'min'
                                })}
                                minWidth='260px'
                                width='49%'
                                currency={currency}
                                number
                            />
                            <Input
                                value={detail.max}
                                label={'transfer-max'}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'max'
                                })}
                                minWidth='260px'
                                width='49%'
                                currency={currency}
                                number
                            />
                        </Background>
                    </Flex>

                    <Flex fw wrap justify='space-between'>
                        <Background bg='detailBox'>
                            <Input
                                value={detail?.receiver?.feeFactor}
                                label={'receiver-fee-factor'}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'receiver.feeFactor'
                                })}
                                minWidth='260px'
                                width='49%'
                                code
                            />
                            <Input
                                value={detail?.sender?.feeFactor}
                                label={'sender-fee-factor'}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'sender.feeFactor'
                                })}
                                minWidth='260px'
                                width='49%'
                                code
                            />
                        </Background>
                    </Flex>

                    <CMargin margin='4px' />
                    <Flex fw justify='flex-start'>
                        <Decoration />
                        <DText main>
                            <Text tid='available-order-note' />
                        </DText>
                    </Flex>
                    <CMargin margin='10px' />
                    <Flex fw wrap justify='space-between'>
                        <Background bg='detailBox'>
                            <Input
                                value={detail?.receiver?.feeMax}
                                label={'receiver-fee-max'}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'receiver.feeMax'
                                })}
                                minWidth='260px'
                                width='49%'
                                currency={currency}
                                number
                            />
                            <Input
                                value={detail?.sender?.feeMax}
                                label={'sender-fee-max'}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'sender.feeMax'
                                })}
                                minWidth='260px'
                                width='49%'
                                currency={currency}
                                number
                            />
                        </Background>
                    </Flex>
                </CFlex>
            }
        </MotionFade>
    )
}


export default TransferUpdate
