import RTableLayout from "../../../layout/RTableLayout";
import useGetTableIndex from "../../../../../core/hooks/layout/useGetTableIndex";
import {CMargin, DText, Flex, Row} from "../../../../../styles/CommonStyles";
import {LineBreak, RespIndex} from "../../../../../styles/main/MainCommonStyles";
import {formatDate, getNames} from "../../../../../core/utils/common";
import Avatar from "../../../../common/utils/Avatar";
import UserLink from "../../../../common/utils/UserLink";
import Text from "../../../../../core/utils/Text";
import {SOCKET_URL} from "../../../../../core/constants/urls";
import {useMainContext} from "../../../../../core/contexts/main";
import UserTag from "../../../../common/utils/UserTag";
import {CopyIcon} from "../../../../../styles/main/reports";
import CoinRow from "../../../../common/common/CoinRow";
import {TradeTypeBadge} from "../../../../../styles/main/orders";
import {useCopyToClipboard} from "../../../../../core/hooks/common/useCopyToClipboard";


const RAddressesTable = ({
    data
}) => {

    const { data: addresses } = data
    const { lang } = useMainContext()
    const { copyToClip } = useCopyToClipboard()

    return (
        <RTableLayout
            data={data}
        >
            {addresses?.data?.map((item, idx) => {
                return (
                    <Row key={item._id} index={idx} cs={'100%'}>
                        <UserTag user={item.user} idx={idx} />
                        <CMargin margin={'2px'} />
                        <Flex fw justify={'flex-start'}>
                            <DText fontSize={'ss'} main style={{width: '52px'}}>
                                <Text tid={'address'} /> :
                            </DText>
                            <DText fontSize={'ss'} primary margin={'0 5px'} style={{ lineBreak: 'anywhere', width: 'calc(100% - 52px)' }} >
                                {item.address}
                            </DText>
                            <CopyIcon size={18} onClick={() => copyToClip(item.address)} />
                        </Flex>
                        <LineBreak />
                        <Flex fw justify={'flex-start'}>
                            <DText fontSize={'ss'} main style={{width: '52px'}}>
                                <Text tid={'memo'} /> :
                            </DText>
                            <DText fontSize={'ss'} primary margin={'0 5px'} style={{ lineBreak: 'anywhere', width: 'calc(100% - 52px)' }} >
                                {item.memo}
                            </DText>
                            <CopyIcon size={18} onClick={() => copyToClip(item.memo)} />
                        </Flex>
                        <LineBreak />
                        <Flex justify={'space-between'}>
                            <DText fontSize={'ss'} main>
                                <Text tid={'name'} /> :
                            </DText>
                            <DText fontSize={'ss'} primary>
                                {item.name}
                            </DText>
                        </Flex>
                        <LineBreak />
                        <Flex justify={'space-between'}>
                            <DText fontSize={'ss'} main>
                                <Text tid={'currency'} /> :
                            </DText>
                            <CoinRow coin={{ currency: item.currency }} />
                        </Flex>
                        <LineBreak />
                        <Flex justify={'space-between'}>
                            <DText fontSize={'ss'} main>
                                <Text tid={'network'} /> :
                            </DText>
                            <TradeTypeBadge>
                                {item.network}
                            </TradeTypeBadge>
                        </Flex>
                        <LineBreak />
                        <Flex justify={'space-between'}>
                            <DText fontSize={'ss'} main>
                                {formatDate(item.createdAt, 'date', lang)}
                            </DText>
                            <DText fontSize={'ss'} main>
                                {formatDate(item.createdAt, 'time', lang)}
                            </DText>
                        </Flex>
                    </Row>
                )
            })}
        </RTableLayout>
    )
}


export default RAddressesTable
