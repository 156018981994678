import {useParams} from "react-router-dom";
import {useGetPayment, useListResyncPayment} from "../../../core/services/react-query/report/payment";
import ObjectView from "../../../components/common/utils/ObjectView";
import {useMainContext} from "../../../core/contexts/main";
import {Column, Row} from "../../../styles/CommonStyles";
import Tabbar from "../../../components/common/tabs/Tabbar";
import {useMemo, useState} from "react";
import ListLayout from "../../../components/layout/main/ListLayout";
import TableLayout from "../../../components/layout/main/TableLayout";
import {resyncListAttributes} from "../../../core/constants/headers";
import CoinRow from "../../../components/common/common/CoinRow";
import {formatNumber} from "../../../core/utils/common";
import {useCopyToClipboard} from "../../../core/hooks/common/useCopyToClipboard";
import {FiCopy} from "react-icons/fi";
import RTableLayout from "../../../components/responsive/layout/RTableLayout";
import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../../core/constants/common";
import ResyncPaymentTable from "../../../components/main/reports/payment/ResyncPaymentTable";
import RResyncPaymentTable from "../../../components/responsive/main/reports/payment/RResyncPaymentTable";


const PaymentDetails = () => {

    const params = useParams()
    const { theme } = useMainContext()
    const { data: payment } = useGetPayment(params.id)

    const [activeTab, setActiveTab] = useState(1)
    const tabs = ['object-view', 'resync-list']

    return (
        <ListLayout>
            <div className={'h-screen w-full'}>
                <Tabbar
                    tabs={tabs}
                    active={activeTab}
                    onTabClicked={setActiveTab}
                />
                {activeTab === 1 ?
                    <div className={'w-full flex items-center justify-center'}>
                        <ObjectView obj={payment} theme={theme} />
                    </div>
                    : null}

                {activeTab === 2 ?
                    <ResyncList paymentId={payment?._id} />
                : null}

            </div>
        </ListLayout>
    )
}


export const useResyncList = (paymentId) => {

    const [activeTab, setActiveTab] = useState(1)

    const { data, isLoading: loading } = useListResyncPayment(paymentId)
    const tabs = useMemo(() => {
        let t = []
        if (!!data) {
            try {
                Object.keys(data).map(key => {
                    t.push(key)
                })
            }catch (_) {}
        }
        return t
    }, [data])

    const activeData = useMemo(() => {
        if (tabs.length && !!data) return data[tabs[activeTab - 1]]
        return []
    }, [tabs, data])


    return {
        tabs, activeTab,
        setActiveTab,
        activeData, loading
    }
}


const ResyncList = ({ paymentId }) => {

    const { width } = useWindowSize()
    const {
        tabs, activeTab,
        setActiveTab,
        activeData, loading
    } = useResyncList(paymentId)

    const ActiveComp = width > TABLET_SIZE ? ResyncPaymentTable : RResyncPaymentTable

    return (
        <>
            <Tabbar
                tabs={tabs}
                active={activeTab}
                onTabClicked={setActiveTab}
            />
            <ActiveComp data={activeData} loading={loading} />
        </>
    )
}

export default PaymentDetails
