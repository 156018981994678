

export const acceptUserOperations = [
    'accept-user',
    'increase-balance',
    'decrease-balance'
]

export const rejectUserOperations = [
    'reject-user',
    'increase-balance',
    'decrease-balance'
]


export const transactionOperations = [
    'approve',
    'reject',
    'rollback'
]

export const availableTokenOperations = [
    'edit'
]

export const notAvailableTokenOperations = [
    'edit'
]


export const bankAccountOperations = [
    'accept-bank', 'reject-bank'
]

export const balanceSignatureOperations = [
    'accept-action',
    'reject-action',
    'delete-signature'
]
