import Text from "../../../../../core/utils/Text";
import CheckBox from "../../../../common/input/CheckBox";
import {useEffect, useState} from "react";
import Input from "../../../../common/input/Input";
import {useGetStaticPrice, useSetStaticPrice} from "../../../../../core/services/react-query/setting/static-price";
import {Background} from "../../../../../styles/CommonStyles";
import ActionButton from "../../../../common/buttons/ActionButton";


const StaticPriceUpdate = ({ coin }) => {

    const { data: staticPrice } = useGetStaticPrice(coin?.currency)
    const { mutate: setStaticPrice, isLoading } = useSetStaticPrice()
    const [showInputs, setShowInputs] = useState(false)

    const [payload, setPayload] = useState({})
    useEffect(() => {
        if (staticPrice?.length) {
            setPayload(staticPrice[0])
            setShowInputs(true)
        } else {
            setPayload({})
            setShowInputs(false)
        }
    }, [staticPrice])

    const onInputChange = (type, v) => {
        setPayload(state => ({...state, [type]: v}))
    }

    const onSubmit = () => {
        let p = {...payload}
        p.currency = coin?.currency
        setStaticPrice(p)
    }

    return (
        <div className={'flex flex-col gap-3 mt-3'}>
            <div className={'flex items-center gap-8 dark:text-white'}>
                <Text tid={'has-static-price'} />
                <CheckBox
                    active={showInputs}
                    onClick={() => setShowInputs(state => !state)}
                />
            </div>
            {showInputs ?
                <>
                    <div className={'grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-10'}>
                        <Background bg='detailBox'>
                            <Input
                                size={'small'}
                                className={'w-full lg:w-[90%] h-[48px]'}
                                currency={coin?.currency}
                                value={payload.buy}
                                number={true}
                                label={'static-buy-price'}
                                onInputChange={(v) => onInputChange('buy', v)}
                            />
                        </Background>
                        <Background bg='detailBox'>
                            <Input
                                size={'small'}
                                className={'w-full lg:w-[90%] h-[48px]'}
                                currency={coin?.currency}
                                value={payload.sell}
                                number={true}
                                label={'static-sell-price'}
                                onInputChange={(v) => onInputChange('sell', v)}
                            />
                        </Background>
                    </div>
                    <ActionButton active className={'w-[300px] h-[42px] mx-auto'} loading={isLoading} onClick={onSubmit}>
                        <Text tid={'submit'} />
                    </ActionButton>
                </>
            : null}
        </div>
    )
}

export default StaticPriceUpdate
