import {useAclContext} from "../../../contexts/acl";
import {useEffect, useState} from "react";
import {balanceSignatureOperations} from "../../../constants/operation";
import {useDeleteMultiSignature, useUpdateMultiSignature} from "../../../services/react-query/multi-signature";
import {useNavigate} from "react-router-dom";


const useCartable = () => {

    const navigate = useNavigate()
    const { permissions } = useAclContext()
    const hasWriteAccess = permissions?.multiSignature?.write
    console.log(permissions)

    const {
        mutate: deleteMultiSignature,
        isLoading: deleteLoading,
        isSuccess: deleteSuccess
    } = useDeleteMultiSignature()

    const actionModalInitial = {
        open: false,
        item: null,
        action: null
    }
    const [actionModal, setActionModal] = useState(actionModalInitial)
    const closeActionModal = () => setActionModal(actionModalInitial)


    const deleteModalInitial = {
        open: false,
        item: null
    }
    const [deleteModal, setDeleteModal] = useState(deleteModalInitial)
    const closeDeleteModal = () => setDeleteModal(deleteModalInitial)

    const onSubmitDelete = () => {
        deleteMultiSignature(deleteModal.item?._id)
    }

    const onOptionClicked = (idx, item) => {
        if (idx === 2) {
            setDeleteModal({
                open: true,
                item
            })
        }else {
            setActionModal({
                open: true,
                action: balanceSignatureOperations[idx],
                item
            })
        }
    }

    const onDeleteClicked = (item) => {
        setDeleteModal({
            open: true,
            item
        })
    }

    const onDetailsClicked = (item) => {
        navigate(`/cartable/${item._id}`)
    }

    useEffect(() => {
        if (deleteSuccess) {
            closeDeleteModal()
        }
    }, [deleteSuccess])


    return {
        hasWriteAccess,
        onOptionClicked,
        onDeleteClicked,
        onDetailsClicked,
        actionModal,
        closeActionModal,
        deleteModal,
        closeDeleteModal,
        onSubmitDelete,
        deleteLoading
    }
}


export default useCartable
