import EChart from "../echart/EChart";
import {useGetPaymentAnalytics} from "../../../core/services/react-query/charts";
import {useMemo} from "react";
import {useMainContext} from "../../../core/contexts/main";


const PaymentDispersionChart = ({ businessId }) => {

    const { theme } = useMainContext()
    const { data: paymentAnalytics } = useGetPaymentAnalytics({businessId})
    const chartData = useMemo(() => {
        let t = {
            names: [],
            series: []
        }
        if (paymentAnalytics?.length) {
            const sortedNumbers = paymentAnalytics?.sort((a, b) => a.amountPaidUsd < b.amountPaidUsd ? 1 : -1)
            for (let i = 0; i < sortedNumbers.length; i++) {
                const curr = sortedNumbers[i]
                const name = `${curr.currency} (${curr.network})`
                t.names.push(name)
                t.series.push({
                    value: curr.amountPaidUsd,
                    name
                })
            }
        }
        return t
    }, [paymentAnalytics])

    const options = {
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} USD ({d}%)'
        },
        legend: {
            data: chartData.names,
            textStyle: {
                color: theme === 'dark' ? '#fafafa' : '#333'
            }
        },
        series: [
            {
                name: 'Payments',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: theme === 'dark' ? '#fff' : 'rgb(209 213 219)',
                    borderWidth: 2
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 18,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: chartData.series
            }
        ]
    }

    return (
        <div dir={'ltr'}>
            <EChart option={options} style={{ width: '100%', height: '360px' }} />
        </div>
    )
}

export default PaymentDispersionChart
