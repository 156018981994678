import { useMainContext } from "../../../contexts/main";
import { makeQueryFilters } from "../../../utils/query-maker";
import { useMutation, useQuery } from "react-query";
import { filterFetch, normalFetch } from "../../fetch-api/get";
import { putApi } from "../../fetch-api/put";
import { postApi } from "../../fetch-api/post";
import { deleteApi } from "../../fetch-api/delete";
import { useQueryContext } from "../../../contexts/query";


const TOKEN_KEYS = {
    GET_ALL_TOKENS: 'get-all-tokens',
    GET_TOKENS: 'get-tokens',
    GET_BLOCKCHAINS: 'get-blockchains',
    GET_A_TOKEN: 'get-a-token',
    UPDATE_TOKEN: 'update-token',
    CREATE_TOKEN: 'create-token',
    DELETE_TOKEN: 'delete-token',
    UPDATE_TOKEN_ICON: 'update-token-icon',
}


const useGetTokens = (filters, pagination) => {

    const { lang } = useMainContext()
    const params = { ...makeQueryFilters(filters, lang), ...pagination }

    return useQuery(
        TOKEN_KEYS.GET_TOKENS, () => filterFetch(params, 'tokens/admin')
    )
}


const useGetAllTokens = () => {

    const { token } = useMainContext()

    return useQuery(
        TOKEN_KEYS.GET_ALL_TOKENS, () => normalFetch('tokens/admin/all'),
        {
            select: res => res?.data?.data,
            enabled: !!token
        }
    )
}

const useGetAToken = (id, onQuerySuccess) => {

    return useQuery(
        TOKEN_KEYS.GET_A_TOKEN, () => normalFetch(`tokens/admin/${id}`),
        {
            select: res => res?.data?.data,
            onSuccess: onQuerySuccess
        }
    )
}


const useGetTokenNetworks = () => {

    // will be dynamic
    return { data: ['eth', 'bsc', 'trx'] }

    return useQuery(
        TOKEN_KEYS.GET_BLOCKCHAINS, () => normalFetch('settings/coins/networks'),
        {
            select: res => res?.data?.data,
            staleTime: 30 * 60 * 1000,
            cacheTime: 30 * 60 * 1000
        }
    )
}


const useUpdateAToken = () => {

    return useMutation(
        TOKEN_KEYS.UPDATE_TOKEN, ({ payload, id }) => putApi(payload, `tokens/${id}`)
    )
}


const useCreateAToken = () => {

    return useMutation(
        TOKEN_KEYS.CREATE_TOKEN, (data) => postApi(data, 'tokens')
    )
}


const useDeleteAToken = () => {

    const { queryClient } = useQueryContext()

    return useMutation(
        TOKEN_KEYS.DELETE_TOKEN, (id) => deleteApi(`tokens/${id}`),
        {
            onSuccess: () => queryClient.invalidateQueries(TOKEN_KEYS.GET_TOKENS)
        }
    )
}


const useSetTokenIcon = () => {

    return useMutation(
        TOKEN_KEYS.UPDATE_TOKEN_ICON, ({ icon, id }) => putApi(icon, `tokens/${id}/icon`)
    )
}

export {
    useGetTokens,
    useGetAllTokens,
    useGetAToken,
    useGetTokenNetworks,
    useUpdateAToken,
    useCreateAToken,
    useDeleteAToken,
    useSetTokenIcon
}
