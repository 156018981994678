import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {useState} from "react";
import {TABLET_SIZE} from "../../../core/constants/common";
import FilterLayout from "../../../components/layout/filter/FilterLayout";
import {
    CacheKeys,
    paymentFilterOptions, paymentSortOptions
} from "../../../core/constants/filter";
import {useListPayments} from "../../../core/services/react-query/report/payment";
import PaymentTable from "../../../components/main/reports/payment/PaymentTable";
import RPaymentTable from "../../../components/responsive/main/reports/payment/RPaymentTable";
import {useParams} from "react-router-dom";
import {UserDetailListWrapper} from "../../../styles/main/user";


const Payment = () => {

    const params = useParams()
    const { width } = useWindowSize()

    const [payments, setPayments] = useState({loading: true, data: null})
    const onPaymentQuery = res => setPayments(res)

    const Component = width > TABLET_SIZE ? PaymentTable : RPaymentTable

    return (
        <UserDetailListWrapper>
            <FilterLayout
                query={useListPayments}
                options={paymentFilterOptions}
                onQuerySuccess={onPaymentQuery}
                cache={CacheKeys.PAYMENT}
                extra={{ userId: params.id }}
                sortOptions={paymentSortOptions}
            >
                <Component data={payments} />
            </FilterLayout>
        </UserDetailListWrapper>
    )
}

export default Payment
