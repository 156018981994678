import {useMainContext} from "../../../../../core/contexts/main";
import {useCopyToClipboard} from "../../../../../core/hooks/common/useCopyToClipboard";
import RTableLayout from "../../../layout/RTableLayout";
import {CMargin, DText, Flex, Row} from "../../../../../styles/CommonStyles";
import UserTag from "../../../../common/utils/UserTag";
import Text from "../../../../../core/utils/Text";
import {CopyIcon} from "../../../../../styles/main/reports";
import {LineBreak} from "../../../../../styles/main/MainCommonStyles";
import CoinRow from "../../../../common/common/CoinRow";
import {TradeTypeBadge} from "../../../../../styles/main/orders";
import {formatDate} from "../../../../../core/utils/common";
import {useNavigate} from "react-router-dom";
import Address from "../../../../common/common/Address";
import BooleanIcon from "../../../../common/utils/BooleanIcon";
import Operation from "../../../../common/utils/Operation";


const RTransactionsTable = ({data}) => {

    const navigate = useNavigate()
    const { lang } = useMainContext()
    const { data: transactions } = data
    const { copyToClip } = useCopyToClipboard()

    const onDetailsClicked = (trans) => {
        navigate(`/reports/transactions/${trans._id}`)
    }


    return (
        <RTableLayout
            data={data}
        >
            {transactions?.data?.map((item, idx) => (
                <Row key={item._id} index={idx} cs={'100%'}>
                    <Flex>
                        <div />
                        <Operation
                            onDetailsClicked={() => onDetailsClicked(item)}
                            hasDetails
                        />
                    </Flex>
                    <CMargin margin={'2px'} />
                    <Flex fw justify={'space-between'}>
                        <DText fontSize={'ss'} main>
                            <Text tid={'fromAddress'} /> :
                        </DText>
                        <Flex>
                            <Address address={item.fromAddress} style={{ margin: '0 8px' }} />
                            <CopyIcon size={18} onClick={() => copyToClip(item.fromAddress)} />
                        </Flex>
                    </Flex>
                    <LineBreak />
                    <Flex fw justify={'space-between'}>
                        <DText fontSize={'ss'} main>
                            <Text tid={'toAddress'} /> :
                        </DText>
                        <Flex>
                            <Address address={item.toAddress} style={{ margin: '0 8px' }} />
                            <CopyIcon size={18} onClick={() => copyToClip(item.toAddress)} />
                        </Flex>
                    </Flex>
                    <LineBreak />
                    <Flex justify={'space-between'}>
                        <DText fontSize={'ss'} main>
                            <Text tid={'currency'} /> :
                        </DText>
                        <CoinRow coin={{ currency: item.currency }} />
                    </Flex>
                    <LineBreak />
                    <Flex justify={'space-between'}>
                        <DText fontSize={'ss'} main>
                            <Text tid={'amount'} /> :
                        </DText>
                        <DText fontSize={'ss'} primary>
                            {item.amount}
                        </DText>
                    </Flex>
                    <LineBreak />
                    <Flex justify={'space-between'}>
                        <DText fontSize={'ss'} main>
                            <Text tid={'network'} /> :
                        </DText>
                        <TradeTypeBadge>
                            {item.network}
                        </TradeTypeBadge>
                    </Flex>
                    <LineBreak />
                    <Flex justify={'space-between'}>
                        <DText fontSize={'ss'} main>
                            <Text tid={'hasPayment'} /> :
                        </DText>
                        <BooleanIcon bool={item.hasPayment} />
                    </Flex>
                    <LineBreak />
                    <Flex justify={'space-between'}>
                        <DText fontSize={'ss'} main>
                            {formatDate(item.createdAt, 'date', lang)}
                        </DText>
                        <DText fontSize={'ss'} main>
                            {formatDate(item.createdAt, 'time', lang)}
                        </DText>
                    </Flex>
                </Row>
            ))}
        </RTableLayout>
    )
}

export default RTransactionsTable
