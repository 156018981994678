import { CFlex, CMargin, Decoration, DText, Flex } from "../../styles/CommonStyles";
import SettingRow from "../common/common/SettingRow";
import CheckBox from "../common/input/CheckBox";
import Text from "../../core/utils/Text";
import { AddTokenIcon } from "../../styles/main/setting";
import { ImageGetter } from "../../styles/main/profile";
import { useEffect, useRef, useState } from "react";
import { useQueryContext } from "../../core/contexts/query";
import { LineBreak } from "../../styles/main/MainCommonStyles";
import ActionButton from "../common/buttons/ActionButton";
import axios from "axios";
import { SOCKET_URL } from "../../core/constants/urls";
import { useCreateAToken, useSetTokenIcon, useUpdateAToken } from "../../core/services/react-query/setting/token";


const AddTokenModal = ({
    tokenIcon,
    tokenData,
    onInputValueChange,
    operationType,
    onClose,
    token
}) => {

    const { setToast } = useQueryContext()
    const iconRef = useRef()

    const { mutate: createToken, isLoading: creatingToken, isSuccess: tokenCreated } = useCreateAToken()
    const { mutate: updateToken, isLoading: updatingToken, isSuccess: tokenUpdated } = useUpdateAToken()
    const { mutate: setTokenIcon } = useSetTokenIcon()

    const LOADING = creatingToken || updatingToken

    const [hasIcon, setHasIcon] = useState(false)

    const updateTokenIcon = () => {
        if (iconRef?.current?.files[0]?.size > 3500000) {
            setToast({
                isError: true,
                show: true,
                message: 'big-image'
            })
        } else {
            setHasIcon(false)
            onInputValueChange(iconRef.current.files[0], 'token-icon')
        }
    }

    const onSubmitClicked = () => {
        if (!!tokenIcon || hasIcon) {
            if (operationType === 'add-token') createToken(tokenData)
            else {
                updateToken({ payload: tokenData, id: token._id })
            }
        }
    }

    useEffect(() => {
        if (!!tokenData.symbol) {
            axios.get(SOCKET_URL + `assets/icon/${tokenData.symbol}.png?${(new Date()).getTime()}`)
                .then(_ => setHasIcon(true))
        }
    }, [])


    useEffect(() => {
        if (tokenCreated || tokenUpdated) {
            onClose()
            if (!!tokenIcon) {
                const files = new FormData();
                files.append("icon", tokenIcon);
                setTokenIcon({ id: token._id, icon: files })
            }

            setToast({
                show: true,
                message: 'operation-success'
            })
        }
    }, [tokenCreated, tokenUpdated])

    return (
        <CFlex fw align={'flex-start'}>

            <DText main>
                <Text tid={'update-token-avatar'} />
            </DText>
            <CMargin margin={'4px'} />
            <Flex>
                <Decoration />
                <DText main fontSize={'s'}>
                    <Text tid={'update-token-avatar-desc'} />
                </DText>
            </Flex>

            <Flex fw>
                <AddTokenIcon>
                    <Flex fw fh>
                        <Text tid={(hasIcon || !!tokenIcon) ? 'update-avatar' : 'add-avatar'} />
                    </Flex>
                    <ImageGetter
                        ref={iconRef}
                        accept='image/png'
                        onChange={updateTokenIcon}
                        type='file'
                        id='tokenIcon'
                        name='tokenIcon'
                    />
                </AddTokenIcon>
                {(tokenIcon || hasIcon) &&
                    <img
                        src={hasIcon ?
                            SOCKET_URL + `assets/icon/${tokenData.symbol}.png`
                            :
                            URL.createObjectURL(tokenIcon)
                        }
                        alt={' '}
                        width={'32px'}
                        height={'32px'}
                        style={{ borderRadius: '50%', margin: '0 10px' }}
                    />
                }
            </Flex>

            <LineBreak />
            <CMargin margin={'4px'} />
            <Flex>
                <Decoration />
                <DText main>
                    <Text tid={'is-active-desc'} />
                </DText>
            </Flex>
            <SettingRow
                prefix='is-active'
                suffix={
                    <CheckBox
                        active={tokenData?.isActive}
                        onClick={
                            () => onInputValueChange(!tokenData?.isActive, 'isActive')
                        }
                    />
                }
            />
            <CMargin margin={'10px'} />
            <ActionButton
                onClick={onSubmitClicked}
                active={!!tokenIcon || hasIcon}
                loading={LOADING}
            >
                <Text tid={'submit'} />
            </ActionButton>
        </CFlex>
    )
}


export default AddTokenModal
