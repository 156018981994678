import {businessPaymentAttributes, paymentAttributes} from "../../../../core/constants/headers";
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex";
import TableLayout from "../../../layout/main/TableLayout";
import {Column, Flex, Relative, Row} from "../../../../styles/CommonStyles";
import CoinRow from "../../../common/common/CoinRow";
import {formatNumber} from "../../../../core/utils/common";
import {TradeTypeBadge} from "../../../../styles/main/orders";
import Text from "../../../../core/utils/Text";
import {getTradeTypeColor} from "../../../../core/utils/theme";
import DateTime from "../../../common/utils/DateTime";
import Operation from "../../../common/utils/Operation";
import UserTag from "../../../common/utils/UserTag";
import Tooltip from "../../../common/utils/Tooltip";
import {CopyIcon, ShowIcon} from "../../../../styles/main/reports";
import {useCopyToClipboard} from "../../../../core/hooks/common/useCopyToClipboard";
import Address from "../../../common/common/Address";
import {useNavigate} from "react-router-dom";


const PaymentTable = ({ data, business }) => {

    const {data: payments} = data
    const {headers, cs} = business ? businessPaymentAttributes : paymentAttributes
    const {getTableIndex} = useGetTableIndex()

    const { copyToClip } = useCopyToClipboard()

    const navigate = useNavigate()
    const onDetailsClicked = (p) => {
        navigate(`/reports/payment/${p._id}`)
    }

    return (
        <TableLayout
            data={data}
            cs={cs}
            headers={headers}
        >
            {payments?.data?.map((payment, idx) => (
                <Relative key={payment._id}>
                    <Row cs={cs} index={idx}>
                        <Column>
                            {getTableIndex(idx)}
                        </Column>
                        {!business ?
                            <Column>
                                <UserTag user={payment.user} idx={idx} />
                            </Column>
                        : null}
                        <Column>
                            <Flex>
                                <Address address={payment.address} />
                                <Tooltip content={payment.address}>
                                    <div>
                                        <ShowIcon size={20} />
                                    </div>
                                </Tooltip>
                                <Tooltip content={'copy-address'}>
                                    <div onClick={() => copyToClip(payment.address)}>
                                        <CopyIcon size={20} />
                                    </div>
                                </Tooltip>
                            </Flex>
                        </Column>
                        <Column>
                            <CoinRow coin={{ currency: payment.currency }} />
                        </Column>
                        <Column>
                            {formatNumber(payment.amount, { type: payment.currency })}
                        </Column>
                        <Column>
                            <TradeTypeBadge>
                                {payment.network}
                            </TradeTypeBadge>
                        </Column>
                        <Column>
                            <TradeTypeBadge>
                                <Text tid={payment.flow} />
                            </TradeTypeBadge>
                        </Column>
                        <Column center>
                            <TradeTypeBadge color={getTradeTypeColor(payment.inUse ? 'success' : 'individual')}>
                                {payment.status}
                            </TradeTypeBadge>
                        </Column>
                        <Column center>
                            <DateTime dt={payment.createdAt} />
                        </Column>
                        <Column operation>
                            <Operation
                                onDetailsClicked={() => onDetailsClicked(payment)}
                                hasDetails
                            />
                        </Column>
                    </Row>
                </Relative>
            ))}
        </TableLayout>
    )
}

export default PaymentTable
