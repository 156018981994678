import DetailCard from "../../../layout/main/details/DetailCard";
import {BalanceTag, CFlex, CMargin, DText, Flex} from "../../../../styles/CommonStyles";
import BooleanIcon from "../../../common/utils/BooleanIcon";
import UserTag from "../../../common/utils/UserTag";
import useWithdrawDetails from "../../../../core/hooks/main/reports/workspace/useWithdrawDetails";
import DetailItem from "../../../layout/main/details/DetailItem";
import Text from "../../../../core/utils/Text";
import {LineBreak} from "../../../../styles/main/MainCommonStyles";
import {useQueryContext} from "../../../../core/contexts/query";
import FilterLayout from "../../../layout/filter/FilterLayout";
import {useListPayments} from "../../../../core/services/react-query/report/payment";
import {
    CacheKeys,
    contractFilterOptions, contractSortOptions,
    paymentFilterOptions,
    paymentSortOptions
} from "../../../../core/constants/filter";
import {useState} from "react";
import {TABLET_SIZE} from "../../../../core/constants/common";
import PaymentTable from "../payment/PaymentTable";
import RPaymentTable from "../../../responsive/main/reports/payment/RPaymentTable";
import {useWindowSize} from "../../../../core/hooks/common/useWindowSize";
import Tabbar from "../../../common/tabs/Tabbar";
import {useListWalletTransactions} from "../../../../core/services/react-query/report/contract";
import ContractTable from "../contract/ContractTable";
import RContractTable from "../../../responsive/main/reports/contract/RContractTable";
import PaymentDispersionChart from "../../../charts/business/PaymentDispersionChart";


const WorkspaceDetailView = ({ workspace }) => {

    const { width } = useWindowSize()
    const { setToast } = useQueryContext()

    const {
        creator
    } = useWithdrawDetails(workspace)

    const tabs = ['payment', 'withdraw']
    const [activeTab, setActiveTab] = useState(1)

    // payments
    const [payments, setPayments] = useState({loading: true, data: null})
    const onPaymentQuery = res => setPayments(res)
    const PaymentComponent = width > TABLET_SIZE ? PaymentTable : RPaymentTable

    // withdraws
    const [withdraws, setWithdraws] = useState({loading: true, data: null})
    const onWithdrawQuery = res => setWithdraws(res)
    const WithdrawComponent = width > TABLET_SIZE ? ContractTable : RContractTable


    const copyToClip = (msg) => {
        navigator.clipboard.writeText(msg)
        setToast({
            error: false, show: true,
            message: 'copy-success'
        })
    }


    return (
        <CFlex fw>
            {/* details */}
            <div className={'grid grid-cols-2 gap-4'}>
                <DetailCard
                    header={'details'}
                    date={workspace?.createdAt}
                >
                    <Flex fw wrap justify={'space-between'}>
                        <DetailItem prefix={'creator'} suffix={<UserTag user={creator} />} />
                        <DetailItem prefix={'name'} suffix={workspace?.name} />
                        <DetailItem prefix={'ip'} suffix={workspace?.ip} />
                        <DetailItem prefix={'category'} suffix={workspace?.category} />
                        <DetailItem prefix={'is-active'} suffix={<BooleanIcon bool={workspace?.isActive} />} />
                    </Flex>
                    <LineBreak />
                    <CFlex fw align={'flex-start'}>
                        <CMargin margin={'5px'} />
                        <Flex fw justify={'flex-start'} align={'flex-start'}>
                            <DText main margin={'0 5px'} style={{ width: '100px' }}>
                                <Text tid={'description'} /> :
                            </DText>
                            <DText primary lineBreak={'anywhere'} style={{ width: 'calc(100% - 150px)' }}>
                                {workspace?.description}
                            </DText>
                        </Flex>
                        <CMargin margin={'5px'} />
                        <Flex fw justify={'flex-start'} align={'flex-start'}>
                            <DText main margin={'0 5px'} style={{ width: '100px' }}>
                                <Text tid={'callback'} /> :
                            </DText>
                            <DText primary lineBreak={'anywhere'} style={{ width: 'calc(100% - 150px)', cursor: 'pointer' }}  onClick={() => copyToClip(workspace?.callback)}>
                                {workspace?.callback}
                            </DText>
                        </Flex>
                        <CMargin margin={'5px'} />
                        <DText main margin={'0 5px'} style={{ width: '100px' }}>
                            <Text tid={'whitelist'} /> :
                        </DText>
                        <Flex fw justify={'flex-start'} align={'flex-start'} wrap>
                            {workspace?.whiteListIps?.map(d => (
                                <BalanceTag style={{ margin: '0 5px' }}>
                                    <DText main>
                                        {d}
                                    </DText>
                                </BalanceTag>
                            ))}
                        </Flex>
                    </CFlex>
                </DetailCard>

                <DetailCard
                    header={'payment-dispersion'}
                >
                    <PaymentDispersionChart
                        businessId={workspace?._id}
                    />
                </DetailCard>
            </div>


            {/* payments */}
            <DetailCard
                header={'payments'}
            >
                <Tabbar
                       active={activeTab}
                       tabs={tabs}
                       onTabClicked={(idx) => setActiveTab(idx)}
                />
                {activeTab === 1 &&
                    <FilterLayout
                        query={useListPayments}
                        options={paymentFilterOptions}
                        onQuerySuccess={onPaymentQuery}
                        cache={CacheKeys.PAYMENT}
                        sortOptions={paymentSortOptions}
                        extra={{ businessId: workspace?._id }}
                    >
                        <PaymentComponent data={payments} business />
                    </FilterLayout>
                }
                {activeTab === 2 &&
                    <FilterLayout
                        query={useListWalletTransactions}
                        options={contractFilterOptions}
                        onQuerySuccess={onWithdrawQuery}
                        cache={CacheKeys.WALLET_TRANSACTIONS}
                        sortOptions={contractSortOptions}
                        extra={{ businessId: workspace?._id }}
                    >
                        <WithdrawComponent data={withdraws} business />
                    </FilterLayout>
                }

            </DetailCard>
        </CFlex>
    )
}


export default WorkspaceDetailView
