import TableLayout from "../../../layout/main/TableLayout";
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex";
import {Column, DText, Flex, Row} from "../../../../styles/CommonStyles";
import {getNames} from "../../../../core/utils/common";
import Avatar from "../../../common/utils/Avatar";
import UserLink from "../../../common/utils/UserLink";
import {SOCKET_URL} from "../../../../core/constants/urls";
import DateTime from "../../../common/utils/DateTime";
import {addressAttributes} from "../../../../core/constants/headers";
import UserTag from "../../../common/utils/UserTag";
import CoinRow from "../../../common/common/CoinRow";
import {TradeTypeBadge} from "../../../../styles/main/orders";
import Tooltip from "../../../common/utils/Tooltip";
import {CopyIcon, ShowIcon} from "../../../../styles/main/reports";
import {useCopyToClipboard} from "../../../../core/hooks/common/useCopyToClipboard";
import Address from "../../../common/common/Address";


const AddressesTable = ({
    data
}) => {

    const { getTableIndex } = useGetTableIndex()
    const { data: addresses } = data
    const { cs, headers } = addressAttributes
    const { copyToClip } = useCopyToClipboard()

    return (
        <TableLayout
            data={data}
            headers={headers}
            cs={cs}
        >
            {addresses?.data?.map((address, idx) => {
                return (
                    <Row index={idx} key={address._id} cs={cs}>
                        <Column>
                            {getTableIndex(idx)}
                        </Column>
                        <Column>
                            <UserTag user={address.user} />
                        </Column>
                        <Column>
                            {address.name}
                        </Column>
                        <Column>
                            <Flex>
                                <Address address={address.address} />
                                <Tooltip content={address.address}>
                                    <div>
                                        <ShowIcon size={20} />
                                    </div>
                                </Tooltip>
                                <Tooltip content={'copy-address'}>
                                    <div onClick={() => copyToClip(address.address)}>
                                        <CopyIcon size={20} />
                                    </div>
                                </Tooltip>
                            </Flex>
                        </Column>
                        <Column>
                            <CoinRow coin={{currency: address.currency}} />
                        </Column>
                        <Column style={{lineBreak: 'anywhere'}}>
                            <TradeTypeBadge>
                                {address.network}
                            </TradeTypeBadge>
                        </Column>
                        <Column>
                            <Flex>
                                { address.memo &&
                                    <>
                                        <DText primary>
                                            {`${address.memo?.substring(0, 6)}...`}
                                        </DText>
                                        <Tooltip content={address.memo}>
                                            <div>
                                                <ShowIcon size={20} />
                                            </div>
                                        </Tooltip>
                                        <Tooltip content={'copy-memo'}>
                                            <div onClick={() => copyToClip(address.memo)}>
                                                <CopyIcon size={20} />
                                            </div>
                                        </Tooltip>
                                    </>
                                
                                }
                              
                            </Flex>
                        </Column>
                        <Column center>
                            <DateTime dt={address.createdAt} />
                        </Column>
                    </Row>
                )
            })}
        </TableLayout>
    )
}

export default AddressesTable
