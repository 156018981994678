import { useMemo, useState } from "react";
import SettingDetailsLayout from "../../../components/layout/main/SettingDetailLayout";
import AvailableCoinsDetail from "../../../components/main/setting/available-coins/AvailableCoinsDetail";
import { useCurrencies } from "../../../core/services/react-query/setting";
import { useGetAllTokens } from "../../../core/services/react-query/setting/token";

const Currencies = () => {

    const initialState = { data: null, loading: true }
    const [data, setData] = useState(initialState)
    const [coin, setCoin] = useState(null)

    const onQuerySuccess = (res) => setData(res)
    const { data: allTokens } = useGetAllTokens()

    // not available tokens
    const tokens = useMemo(() => {
        let temp = []
        if (data?.data && !!allTokens) {
            const symbols = data?.data?.map(x => x.currency)
            console.log(data, symbols)
            for (let i = 0; i < allTokens?.length; i++) {
                const curr = allTokens[i]
                if (symbols.includes(curr.currency)) continue
                temp.push(curr)
            }
        }
        return temp
    }, [allTokens, data])

    return (
        <SettingDetailsLayout
            query={useCurrencies}
            onQuerySuccess={onQuerySuccess}
        >
            <AvailableCoinsDetail
                data={data}
                coinState={{ coin, setCoin }}
                tokens={tokens}
            />
        </SettingDetailsLayout>
    )
}

export default Currencies;
