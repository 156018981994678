import styled, { css } from "styled-components";
import { IoNotificationsSharp } from 'react-icons/io5'
import { HiOutlineMenuAlt4 } from 'react-icons/hi'


export const HeaderWrapper = styled.div`
  background-color: ${props => props.theme.mainBg};
  font-family: ${props => props.theme.english ? "Roboto" : 'Vazir'};
  direction: ${props => props.theme.english ? 'ltr' : 'rtl'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 0 ${props => props.resp ? "15px" : "50px"};
`


export const NotificationIcon = styled(IoNotificationsSharp)`
  color: ${props => props.theme.color};
`


export const MobileMenu = styled(HiOutlineMenuAlt4)`
  color: ${props => props.theme.color};
  margin: 0 8px;
`


