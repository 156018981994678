import { useLocation } from "react-router-dom";
import ListLayout from "../../../components/layout/main/ListLayout";
import { Background, CFlex, CMargin, DText, Flex } from "../../../styles/CommonStyles";
import { SettingCardWrapper } from "../../../styles/main/setting";
import { useGetAToken, useGetTokenNetworks } from "../../../core/services/react-query/setting/token";
import { useEffect, useState } from "react";
import Text from "../../../core/utils/Text";
import Input from "../../../components/common/input/Input";
import Select from "../../../components/common/dropdown/Select";
import { LineBreak } from "../../../styles/main/MainCommonStyles";
import ActionButton from "../../../components/common/buttons/ActionButton";
import { useQueryContext } from "../../../core/contexts/query";
import ModalLayout from "../../../components/layout/main/ModalLayout";
import AddTokenModal from "../../../components/modals/AddTokenModal";
import Tooltip from "../../../components/common/utils/Tooltip";
import { stringToNumber } from "../../../core/utils/common";
import Loader from "../../../components/common/loading/Loader";



const INPUT_TYPES = {
    CURRENCY: 'currency',
    DECIMAL: 'decimal',
    CONTRACT: 'contract',
    IS_ACTIVE: 'isActive',
    TOKEN_ICON: 'token-icon',
    MIN_BALANCE: 'minBalanceToMove'
}

const TokenOperation = () => {

    const { setToast } = useQueryContext()
    const location = useLocation()
    const search = new URLSearchParams(location.search)
    const operationType = search.get('type')
    const EDIT_MODE = operationType === 'edit'
    const tokenId = search.get('tokenId')

    const initialState = {
        currency: '', decimal: '',
        network: '', contract: '',
        isActive: false, minBalanceToMove: 0
    }
    const [tokenIcon, setTokenIcon] = useState(null)
    const [tokenData, setTokenData] = useState(initialState)
    const [validOperation, setValidOperation] = useState(false)
    const [step2Modal, setStep2Modal] = useState(false)

    const { data: networks } = useGetTokenNetworks()
    const { data: token, refetch: getToken, isLoading: loadingToken } = useGetAToken(tokenId, false)

    useEffect(() => {
        if (tokenId && EDIT_MODE) {
            getToken()
        } else {
            setTokenData(initialState)
        }
    }, [])

    useEffect(() => {
        if (token && EDIT_MODE) setTokenData({
            currency: token.currency, network: token.network,
            contract: token.contract, decimal: token.decimal,
            isActive: token.isActive, minBalanceToMove: token.minBalanceToMove
        })
    }, [token])

    useEffect(() => {
        const valid =
            !!tokenData.contract &&
            !!tokenData.network &&
            !!tokenData.decimal && !!tokenData.currency
        setValidOperation(valid)
    }, [tokenData])

    const onInputValueChange = (v, type) => {
        let value = v
        if (type === INPUT_TYPES.TOKEN_ICON) {
            setTokenIcon(value)
        }
        else {
            if (type === INPUT_TYPES.DECIMAL) value = stringToNumber(value)
            setTokenData(state => ({ ...state, [type]: value }))
        }
    }


    const onNetworkChange = (idx) => {
        setTokenData(state => ({
            ...state,
            network: networks[idx]
        }))
    }

    const onSubmitChanges = () => {
        if (validOperation) {
            setStep2Modal(true)
        } else {
            setToast({
                isError: true,
                show: true,
                message: 'fill-input-errors'
            })
        }
    }

    if (loadingToken) {
        return  (
            <ListLayout>
                <SettingCardWrapper>
                    <div className={'flex justify-center items-center w-full min-h-[240px]'}>
                        <Loader />
                    </div>
                </SettingCardWrapper>
            </ListLayout>
        )
    }

    return (
        <ListLayout>
            <CFlex fw>
                <SettingCardWrapper>
                    <Flex fw justify={'flex-start'}>
                        <DText main fontSize={'b'}>
                            <Text tid={operationType} />
                        </DText>
                    </Flex>
                    <CFlex fw align={'flex-start'}>
                        <CMargin margin={'15px'} />
                        <DText main>
                            <Text tid={'network'} />
                        </DText>

                        <Flex fw wrap justify={'space-between'}>
                            <Background bg={'detailBox'}>
                                <Select
                                    value={tokenData.network}
                                    options={networks || []}
                                    onValueChange={onNetworkChange}
                                    width={'300px'}
                                    placeHolder={'select-network'}
                                />
                                <Input
                                    value={tokenData.minBalanceToMove}
                                    onInputChange={(v) => onInputValueChange(v, INPUT_TYPES.MIN_BALANCE)}
                                    minWidth={'260px'}
                                    width={'300px'}
                                    label={'min-balance-to-move'}
                                />
                            </Background>

                        </Flex>

                        <CMargin margin={'4px'} />
                        <LineBreak />
                        <CMargin margin={'4px'} />

                        <Flex fw wrap justify={'space-between'}>
                            <Background bg={'detailBox'}>
                                <Input
                                    value={tokenData.contract}
                                    onInputChange={(v) => onInputValueChange(v, INPUT_TYPES.CONTRACT)}
                                    minWidth={'260px'}
                                    width={'600px'}
                                    label={'smart-contract'}
                                    valid={!!tokenData.contract}
                                />
                                <Input
                                    value={tokenData.currency}
                                    onInputChange={(v) => onInputValueChange(v, INPUT_TYPES.CURRENCY)}
                                    minWidth={'260px'}
                                    width={'300px'}
                                    label={'symbol'}
                                    valid={!!tokenData.currency}
                                />
                                <Input
                                    value={tokenData.decimal}
                                    onInputChange={(v) => onInputValueChange(v, INPUT_TYPES.DECIMAL)}
                                    minWidth={'260px'}
                                    width={'300px'}
                                    label={'decimal'}
                                    valid={!!tokenData.decimal}
                                    number
                                />
                            </Background>
                        </Flex>

                        <CMargin margin={'4px'} />
                        <LineBreak />
                        <CMargin margin={'14px'} />

                        <Flex fw>
                            <ActionButton
                                active={validOperation}
                                width={'300px'}
                                onClick={onSubmitChanges}
                            >
                                <Text tid={'submit-changes'} />
                            </ActionButton>
                        </Flex>

                    </CFlex>
                </SettingCardWrapper>
                <ModalLayout
                    width={'480px'}
                    open={step2Modal}
                    onClose={() => setStep2Modal(false)}
                >
                    <AddTokenModal
                        tokenData={tokenData}
                        onInputValueChange={onInputValueChange}
                        tokenIcon={tokenIcon}
                        operationType={operationType}
                        onClose={() => setStep2Modal(false)}
                        token={token}
                    />
                </ModalLayout>
            </CFlex>
        </ListLayout>
    )
}


export default TokenOperation
