import FilterLayout from "../../../components/layout/filter/FilterLayout";
import {useState} from "react";
import {useGetAddresses} from "../../../core/services/react-query/report/address";
import {addressFilterOptions, addressSortOptions, CacheKeys} from "../../../core/constants/filter";
import AddressesTable from "../../../components/main/reports/address/AddressesTable";
import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../../core/constants/common";
import RAddressesTable from "../../../components/responsive/main/reports/address/RAddressesTable";
import {UserDetailListWrapper} from "../../../styles/main/user";
import {useParams} from "react-router-dom";


const Addresses = () => {

    const params = useParams()
    const { width } = useWindowSize()
    const [addresses, setAddresses] =  useState({data: null, loading: true})

    const onQuerySuccess = (res) => setAddresses(res)

    const Component = width > TABLET_SIZE ? AddressesTable : RAddressesTable

    return (
        <UserDetailListWrapper>
            <FilterLayout
                query={useGetAddresses}
                cache={CacheKeys.ADDRESSES}
                options={addressFilterOptions}
                onQuerySuccess={onQuerySuccess}
                extra={{ userId: params.id }}
                sortOptions={addressSortOptions}
            >
                <Component
                    data={addresses}
                />
            </FilterLayout>
        </UserDetailListWrapper>
    )
}


export default Addresses
