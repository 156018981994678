import { useParams } from "react-router-dom";
import { useGetAToken } from "../../../core/services/react-query/setting/token";
import { useGetAUser } from "../../../core/services/react-query/user";
import { useEffect } from "react";
import CardDetailsLayout from "../../../components/layout/main/details/CardDetailsLayout";
import { DetailRow, LeftHeadDecoration, RightHeadDecoration } from "../../../styles/main/MainCommonStyles";
import { CMargin, DText, Flex } from "../../../styles/CommonStyles";
import { SOCKET_URL } from "../../../core/constants/urls";
import Text from "../../../core/utils/Text";
import Avatar from "../../../components/common/utils/Avatar";
import { formatDate, formatName, getNames } from "../../../core/utils/common";
import UserLink from "../../../components/common/utils/UserLink";
import { TradeAmount, TradeTypeBadge } from "../../../styles/main/orders";
import { getTradeTypeColor } from "../../../core/utils/theme";
import { useMainContext } from "../../../core/contexts/main";
import Operation from "../../../components/common/utils/Operation";
import useTokens from "../../../core/hooks/main/setting/tokens/useTokens";
import ModalLayout from "../../../components/layout/main/ModalLayout";
import BasicModal from "../../../components/modals/BasicModal";


const TokenDetails = () => {

    const params = useParams()
    const { lang } = useMainContext()

    const {
        onOptionClicked,
        deleteModal,
        onDeleteModalClose,
        onDeleteToken,
        deletingToken
    } = useTokens()


    const { data: token, isFetching, isError } = useGetAToken(params.id)
    // const { data: user, refetch: getUser } = useGetAUser(token?.userId)

    // useEffect(() => {
    //     if (token) {
    //         getUser()
    //     }
    // }, [token])


    // const { NAMEAVATAR, FULLNAME } = getNames(user?.firstName, user?.lastName)

    return (
        <CardDetailsLayout
            isFetching={isFetching}
            isError={isError}
            type={token?.isActive ? 'success' : 'error'}
        >
            <RightHeadDecoration>
                <Flex>
                    <img
                        src={SOCKET_URL + `assets/icon/${token?.symbol}.png`}
                        alt={' '}
                        width={'48px'}
                    />
                </Flex>
            </RightHeadDecoration>
            <LeftHeadDecoration left={'10px'}>
                <DText main>
                    {params.id}
                </DText>
            </LeftHeadDecoration>
            <Flex fw justify='space-between'>
                <DText fontSize={'b'} primary>
                    {token?.symbol?.toUpperCase()}
                </DText>
                <Operation
                    options={['edit', 'delete']}
                    onOptionClicked={(idx) => onOptionClicked(idx, token)}
                    hasWriteAccess
                />
            </Flex>
            {/* <Flex justify={'flex-start'} style={{ margin: '8px 0' }}>
                <Avatar
                    name={NAMEAVATAR}
                    avatar={user?.avatar}
                />
                <UserLink
                    name={formatName(FULLNAME)}
                    _id={token?.userId}
                />
            </Flex> */}
            <CMargin margin={'10px'} />
            <DetailRow>
                <DText main>
                    <Text tid={'smart-contract'} />
                </DText>
                <Flex justify={'flex-end'}>
                    <DText primary lineBreak={'anywhere'}>
                        {token?.contract}
                    </DText>
                </Flex>
            </DetailRow>
            <DetailRow>
                <DText main>
                    <Text tid={'network'} />
                </DText>
                <Flex justify={'flex-end'}>
                    <TradeTypeBadge color={getTradeTypeColor('limit')}>
                        {token?.network}
                    </TradeTypeBadge>
                </Flex>
            </DetailRow>
            <DetailRow>
                <DText main>
                    <Text tid={'decimal'} />
                </DText>
                <Flex justify={'flex-end'}>
                    <DText primary lineBreak={'anywhere'}>
                        {token?.decimal}
                    </DText>
                </Flex>
            </DetailRow>
            <DetailRow>
                <DText main>
                    <Text tid={'status'} />
                </DText>
                <Flex justify={'flex-end'}>
                    <TradeAmount type={token?.isActive ? 'buy' : 'sell'}>
                        <Text tid={token?.isActive ? 'is-active' : 'not-active'} />
                    </TradeAmount>
                </Flex>
            </DetailRow>
            <Flex fw justify={'space-between'} style={{ marginTop: '20px' }}>
                <DText main>
                    {formatDate(token?.createdAt, 'date', lang)}
                </DText>
                <DText main>
                    {formatDate(token?.createdAt, 'time', lang)}
                </DText>
            </Flex>

            <ModalLayout
                onClose={onDeleteModalClose}
                open={deleteModal.open}
                width={'520px'}
            >
                <BasicModal
                    onClose={onDeleteModalClose}
                    _id={deleteModal.token?._id}
                    loading={deletingToken}
                    head={'delete-token-head'}
                    desc={'delete-token-desc'}
                    onSubmit={() => onDeleteToken(true)}
                />
            </ModalLayout>

        </CardDetailsLayout>
    )
}


export default TokenDetails
