/**
 *
 * @param {string} theme
 * @param {string} lang
 * @return {Object}
 */
const getMainTheme = (theme, lang) => {

    const MainTheme = {
        dark: {
            mainBg: "#0a1b2a",
            secondaryBg: "#0a141d",
            primaryBg: "#0a141d",
            color: "#c3c5b7",
            secondary: "#eaecef",
            primary: "white",
            hover: "#26282b70",
            active: "#197f64",
            exitRed: "#a12356",
            mainOrange: "#ffc800",
            mainRed: "#e9106c",
            mainGreen: "#1ce087",
            search: "#161a1e",
            input: "#3f4243",
            textArea: "#44464c",
            dropdown: "#36383C",
            tradeBg: "#1e2026",
            tInputBg: "#1A252E",
            otcInput: "#0e111a",
            mChart: "#131722",
            orderHover: "#5e6673",
            inActive: '#72747830',
            shadow: "#000",
            skeletonBg: "#1e2026",
            skeleton: "#1A252E",
            shimmer: "rgba(255,255,255, 0.05)",
            sortHeader: "#212427",
            detailBox: '#0a1b2a'
        },
        light: {
            mainBg: "white",
            secondaryBg: "#fafafa",
            primaryBg: "#fafafa",
            color: "#595e67",
            secondary: "#595e67",
            primary: "#0e0f10",
            hover: "#fafafa70",
            active: "#197f64",
            exitRed: "#a12356",
            mainOrange: "#f0b90b",
            mainRed: "#f6465d",
            mainGreen: "#37b88b",
            search: "#b7bdc6",
            input: "#fafafa",
            textArea: "#f1f2f3",
            dropdown: "#707a8a",
            tradeBg: "#ffffff",
            tInputBg: "#f0f1f2",
            otcInput: "#f0f1f2",
            mChart: "wheat",
            orderHover: "#f0f1f2",
            inActive: '#72747830',
            shadow: "#00000060",
            skeletonBg: "#f2f2f2",
            skeleton: "#ddd",
            shimmer: "rgba(255,255,255, 0.2)",
            sortHeader: "#f8f8f8",
            detailBox: "#f7f7f7",
        }
    };

    const chosenTheme = { ...MainTheme[theme] }
    chosenTheme.english = lang === "en"
    return chosenTheme
}


const fadeVariants = {
    out: {
        opacity: 0, transition: { duration: 0.3 }
    },
    in: {
        opacity: 1, transition: { duration: 0.3 }
    }
}


const getRandomColor = (idx) => {
    const randomColor = [
        '#818F9F', '#9F819E', '#9F9181', '#819F82'
    ]
    return randomColor[idx % 3]
}

const getUserTagColors = (idx, theme) => {
    const colors = [
        { dark: '#4487c990', light: '#70c8ff90' },
        { dark: '#2db19190', light: '#67ccb490' },
        { dark: '#f5fcaa90', light: '#f5fcaa90' },
        { dark: '#e7717d90', light: '#ff738190' },
    ]
    return colors[idx % 4][theme]
}

const getTradeTypeColor = (type) => {
    const typedColors = {
        oco: '#819F82',
        stop: '#9F819E',
        market: '#9F9181',
        limit: '#818F9F',
        pending: '#9F9181',
        success: '#819F82',
        error: '#9F819E',
        canceled: '#9F9181',
        timeout: '#9F819E',
        order: '#819F82',
        withdraw: '#9F9181',
        deposit: '#818F9F',
        otc: '#9F9181',
        buy: '#819F82',
        sell: '#9F819E',
        banking: '#9F819E',
        coining: '#9F819E',
        maker: '#819F82',
        taker: '#9F9181',
        completed: '#819F82',
        increase: '#819F82',
        decrease: '#9F819E',
        rejected: '#9F819E',
        done: '#819F82',
        day: '#9F9181',
        month: '#9F819E',
        week: '#818F9F',
        total: '#819F82',
        admin: '#819F82',
        user: '#9F819E',
        individual: '#818F9F',
        waitingForApprove: '#9F9181'
    }

    return typedColors[type]
}


export {
    getMainTheme,
    fadeVariants,
    getRandomColor,
    getTradeTypeColor,
    getUserTagColors
}
