import styled, {css} from "styled-components";
import {IoIosArrowForward} from "react-icons/io";
import {HiChevronDoubleLeft} from "react-icons/hi";
import {AiOutlineEye} from "react-icons/ai";
import {BiCopy} from "react-icons/bi";


export const MemberRow = styled.div`
  display: grid;
  grid-template-columns: 30% 10% 60%;
  width: 100%;
  margin: 8px 0;
  column-gap: 6px;
  align-items: center;
  
  @media screen and (max-width: 600px) {
    grid-template-columns: 70% 30%;
  };
`

export const WorkspacePermItem = styled.div`
  width: 33%;
  margin-top: 4px;
  padding: 0 4px;
  ${props => !props.theme.english && css`
    border-left: 1px solid ${props => props.theme.color}20;
  `};
  ${props => props.theme.english && css`
    border-right: 1px solid ${props => props.theme.color}20;
  `};
`

export const DetailsIcon = styled(IoIosArrowForward)`
  transform: rotate(0deg);
  color: ${props => props.theme.color};
  ${props => !props.theme.english && css`
    transform: rotate(180deg);
  `};
`

export const ContractMemberRow = styled.div`
  display: grid;
  grid-template-columns: 60% 20% 20%;
  width: 100%;
  margin: 8px 0;
  column-gap: 6px;
  align-items: center;
`

export const PaymentApproveListRow = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  width: 100%;
  margin: 8px 0;
  column-gap: 6px;
  align-items: center;
`

export const PaymentChangeTag = styled.div`
  background-color: ${props => props.theme.mainBg};
  border: 1px solid ${props => props.theme.color}20;
  border-radius: 4px;
  padding: 6px 12px;
  min-width: 128px;
  margin: 8px;
`

export const PaymentChangeArrow = styled(HiChevronDoubleLeft)`
  color: ${props => props.theme.mainOrange};
  margin: 0 4px;
  ${props => !props.theme.english && css`
    transform: rotate(0deg);
  `};
  ${props => props.theme.english && css`
    transform: rotate(180deg);
  `};
`

export const ShowIcon = styled(AiOutlineEye)`
  color: ${props => props.theme.primary};
  cursor: pointer;
`

export const CopyIcon = styled(BiCopy)`
  color: ${props => props.theme.primary};
  margin: 0 4px;
  cursor: pointer;
`
