


const AffiliateListDetails = () => {



    return (
     <></>
    )
}


export default AffiliateListDetails
