import { useEffect, useState } from "react"
import { useSetCurrencies } from "../../../../services/react-query/setting"
import { deepCopy, makeObjectFormattedNumber, stringToNumber } from "../../../../utils/common"
import { useQueryContext } from "../../../../contexts/query";
import {useSearchParams} from "react-router-dom";


const availableFormat = {
    name: '',
    currency: '',
    type: '',
    decimal: '',
    color: '',
    wallet: { deposit: [], withdraw: [] }
}


export const AC_SETTING_TYPES = {
    ALL: 'all',
    DETAILS: 'details',
    SWAP: 'swap',
    TRANSFER: 'transfer',
    DEPOSIT_WITHDRAW: 'deposit-withdraw-fee',
    STATIC_PRICE: 'static-price'
}


const useCurrencySetting = (currencies, coinState, tokens) => {

    const { setToast } = useQueryContext()

    const initialState = {
        [AC_SETTING_TYPES.DETAILS]: false,
        [AC_SETTING_TYPES.TRANSFER]: false,
        [AC_SETTING_TYPES.SWAP]: false,
        [AC_SETTING_TYPES.DEPOSIT_WITHDRAW]: false,
        [AC_SETTING_TYPES.STATIC_PRICE]: false
    }

    const openAllEditBoxes = () => {
        const temp = {}
        Object.keys(initialState).forEach(key => {
            temp[key] = true
        })
        setEditState(temp)
    }

    const [editState, setEditState] = useState(initialState)

    // modes -> open, close, all, add
    const [editMode, setEditMode] = useState('close')

    const { coin, setCoin } = coinState
    const [deleteModal, setDeleteModal] = useState(false)
    const onDeleteModalOpen = () => setDeleteModal(true)
    const onDeleteModalClose = () => setDeleteModal(false)
    const onCoinsSet = () => {
        setEditState(initialState)
        setEditMode('close')
    }

    const { mutate: updateAvailableCoins, isLoading: loading, isSuccess: coinsUpdated } = useSetCurrencies(onCoinsSet)

    const addDefaultNetworkObject = (curr) => {
        curr.wallet = {}
        curr.wallet.deposit = []
        curr.wallet.withdraw = []
        curr.color = 'ffffff'
    }

    // useEffect(() => {
    //     const coinName = searchParams.get('coin')
    //     if (!!coinName) {
    //         let coinTemp = tokens?.find(x => x.currency === coinName)
    //         if (!coinTemp) return
    //         addDefaultNetworkObject(coinTemp)
    //         setCoin(coinTemp)
    //         openAllEditBoxes()
    //         setEditMode('add')
    //     }
    // }, [tokens])

    const onCoinChange = (idx) => {
        let currency;
        if (editMode === 'add') {
            currency = tokens[idx]
            addDefaultNetworkObject(currency)
        }
        else currency = currencies[idx]
        setCoin(currency)
    }

    const enterEditMode = (type) => {
        if (!coin && type !== 'add') {
            setToast({
                show: true,
                isError: true,
                message: 'select-coin-to-see-details'
            })
            return
        }

        switch (type) {
            case 'add':
                setCoin(availableFormat)
                setEditMode(type)
                openAllEditBoxes()
                break
            case 'all':
                setEditMode(type)
                openAllEditBoxes()
                break
            default:
                setEditMode('open')
                setEditState(state => ({ ...state, [type]: true }))
        }
    }

    const exitEditMode = () => {
        setEditMode('close')
        setEditState(initialState)

        if (editMode === 'add') {
            setCoin(null)
        } else {
            if (coin) {
                const _coin = currencies.find(item => item.currency === coin.currency)
                // setCoin(formatCoin(_coin))
                setCoin(_coin)
            }
        }
    }


    const onDetailChange = (data) => {

        /**
         * @param {string} value - value
         * @param {string} type - ac-settings-type
         * @param {string} key - key of the object that should change
         * @param {boolean} isBoolean - for isActive && isStatic
         * @param {number} listIdx - idx for deposit and withdraw list
         */
        const { value, type, key, isBoolean, listIdx } = data
        if (key.includes('actor')) {
            if (value > 100) return
        }

        switch (type) {
            case AC_SETTING_TYPES.DETAILS:
                if (isBoolean) setCoin(state => ({ ...state, [key]: !state[key] }))
                else setCoin(state => ({ ...state, [key]: value }))
                break
            case AC_SETTING_TYPES.TRANSFER:
                const transfer = { ...coin.transfer }
                if (key.includes('.')) {
                    const keys = key.split('.')
                    transfer[keys[0]][keys[1]] = value
                } else {
                    if (isBoolean) transfer[key] = !transfer[key]
                    else transfer[key] = value
                }
                setCoin(state => ({ ...state, transfer }))
                break
            case AC_SETTING_TYPES.SWAP:
                const swap = { ...coin.swap }
                if (key.includes('.')) {
                    const keys = key.split('.')
                    if (isBoolean) swap[keys[0]][keys[1]] = !swap[keys[0]][keys[1]]
                    else swap[keys[0]][keys[1]] = value
                } else {
                    swap[key] = value
                }
                setCoin(state => ({ ...state, swap }))
                break
            case 'deposit':
                const deposit = deepCopy(coin.wallet.deposit)
                deposit[listIdx][key] = value
                setCoin(state => ({ ...state, wallet: { ...state.wallet, deposit } }))
                break
            case 'withdraw':
                const withdraw = deepCopy(coin.wallet.withdraw)
                withdraw[listIdx][key] = value
                setCoin(state => ({ ...state, wallet: { ...state.wallet, withdraw } }))
                break
            default:
                break
        }
    }

    /**
     * @param {object} network - new network to add
     * @param {string} type - depositList, withdrawList
     */
    const onAddNetwork = (network, type) => {
        const _coin = deepCopy(coin)
        _coin.wallet[type].push({ ...network, icon: `icons/${network.network}.png` })
        setCoin(_coin)
    }

    /**
     * @param {object} idx - network index to remove
     * @param {string} type - depositList, withdrawList
     */
    const onDeleteNetwork = (idx, type) => {
        const _coin = deepCopy(coin)
        _coin.wallet[type].splice(idx, 1)
        setCoin(_coin)
    }

    const fixNumbers = (coin) => {
        try {
            const temp = deepCopy(coin)

            const exceptions = ['network', 'name', 'delay', 'icon', 'isActive', 'color']
            const listTypes = ['deposit', 'withdraw']
            listTypes.forEach(type => {
                temp.wallet[type].forEach((item, idx) => {
                    Object.keys(item).forEach(key => {
                        if (!exceptions.includes(key)) {
                            const newValue = stringToNumber(temp.wallet[type][idx][key])
                            if (newValue !== null) {
                                temp.wallet[type][idx][key] = newValue
                            }
                        }
                    })
                })
            })
            return temp
        }
        catch (err) {
            console.log(err, 'fix numbers')
        }
    }

    const onSubmitChanges = () => {
        const coins = [...currencies]
        const coinWithIcon = { ...coin, icon: `icons/${coin.currency}.png` }
        if (editMode === 'add') {
            delete coinWithIcon._id
            delete coinWithIcon.network
            delete coinWithIcon.contract
            delete coinWithIcon.minBalanceToMove
            coins.push(fixNumbers(coinWithIcon))
        } else {
            const idx = coins.findIndex(item => item.currency === coin.currency)
            coins[idx] = fixNumbers(coinWithIcon)
        }
        updateAvailableCoins(coins)
    }

    const onDeleteAvailableCoin = () => {
        const newCoinSet = currencies.filter(item => item.currency !== coin?.currency)
        updateAvailableCoins(newCoinSet)
        setCoin(null)
    }

    useEffect(() => {
        if (coinsUpdated) onDeleteModalClose()
    }, [coinsUpdated])

    return {
        editState,
        coin,
        onCoinChange,
        enterEditMode,
        exitEditMode,
        editMode,
        onDetailChange,
        onAddNetwork,
        onDeleteNetwork,
        onSubmitChanges,
        loading,
        onDeleteAvailableCoin,
        deleteModal,
        onDeleteModalClose,
        onDeleteModalOpen
    }
}


export default useCurrencySetting
