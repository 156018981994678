import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {useState} from "react";
import {TABLET_SIZE} from "../../../core/constants/common";
import ListLayout from "../../../components/layout/main/ListLayout";
import {PageCard} from "../../../styles/CommonStyles";
import FilterLayout from "../../../components/layout/filter/FilterLayout";
import {
    CacheKeys,
    transactionFilterOptions, transactionSortOptions
} from "../../../core/constants/filter";
import TransactionsTable from "../../../components/main/reports/transactions/TransactionsTable";
import RTransactionsTable from "../../../components/responsive/main/reports/transactions/RTransactionsTable";
import {useTransactionsList} from "../../../core/services/react-query/report/transactions";


const Transactions = () => {
    const { width } = useWindowSize()

    const [transactions, setTransactions] = useState({loading: true, data: null})
    const onUserQuery = res => setTransactions(res)

    const Component = width > TABLET_SIZE ? TransactionsTable : RTransactionsTable

    return (
        <ListLayout>
            <PageCard>
                <FilterLayout
                    query={useTransactionsList}
                    options={transactionFilterOptions}
                    onQuerySuccess={onUserQuery}
                    cache={CacheKeys.TRANSACTIONS}
                    sortOptions={transactionSortOptions}
                >
                    <Component data={transactions} />
                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}

export default Transactions
