import {useMutation, useQuery} from "react-query";
import {normalFetch} from "../../fetch-api/get";
import {postApi} from "../../fetch-api/post";


export const StaticPriceKeys = {
    getStaticPrice: 'getStaticPrice',
    setStaticPrice: 'setStaticPrice'
}

export const useGetStaticPrice = (currency) => {


    return useQuery(
        [StaticPriceKeys.getStaticPrice, currency], () => normalFetch(`staticPrices?search=currency&query=${currency}&limit=1`),
        {
            select: res => res?.data?.data,
            enabled: !!currency
        }
    )
}

export const useSetStaticPrice = () => {

    return useMutation(
        [StaticPriceKeys.setStaticPrice], (payload) => postApi(payload, 'staticPrices')
    )
}
