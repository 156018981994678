import {useQuery} from "react-query";
import {useMainContext} from "../../../../contexts/main";
import {makeQueryFilters} from "../../../../utils/query-maker";
import {filterFetch, normalFetch} from "../../../fetch-api/get";


export const CONTRACT_KEYS = {
    LIST_PAYMENTS: 'list-payments',
    GET_PAYMENT: 'get-payment',
    resyncList: 'resync-list'
}


const useListPayments = (filters, pagination, extra, csv) => {

    const { lang } = useMainContext()
    const newFilters = {...filters, ...extra}
    const params = {...makeQueryFilters(newFilters, lang), ...pagination}
    if (csv){
        params.export = 'csv'
    }

    return useQuery(
        CONTRACT_KEYS.LIST_PAYMENTS, () => filterFetch(params, 'payments/admin')
    )
}

const useGetPayment = (id) => {

    return useQuery(
        [CONTRACT_KEYS.GET_PAYMENT, id], () => normalFetch(`payments/admin/${id}`),
        {
            select: res => res?.data?.data,
            enabled: !!id
        }
    )
}

export const useListResyncPayment = (id) => {

    return (
        useQuery(
            [CONTRACT_KEYS.resyncList, id], () => normalFetch(`payments/admin/resync/${id}`),
            {
                select: res => res?.data?.data,
                enabled: !!id
            }
        )
    )
}

export {
    useListPayments,
    useGetPayment
}
