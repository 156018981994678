import RTableLayout from "../../../layout/RTableLayout";
import {Row} from "../../../../../styles/CommonStyles";
import Text from "../../../../../core/utils/Text";
import CoinRow from "../../../../common/common/CoinRow";
import {useCopyToClipboard} from "../../../../../core/hooks/common/useCopyToClipboard";
import {FiCopy} from "react-icons/fi";
import {formatNumber} from "../../../../../core/utils/common";
import {MiniLineBreak} from "../../../../../styles/main/MainCommonStyles";


const RResyncPaymentTable = ({ data, loading }) => {

    const { copyToClip } = useCopyToClipboard()

    return (
        <RTableLayout
            data={{ data: { data }, loading }}
            hasPagination={false}
        >
            {data?.map((item, idx) => {

                return (
                    <Row cs={'100%'} key={item.txId}>
                        <div className={'grid grid-cols-2 gap-4'}>
                            <Text tid={'currency'} />
                            <CoinRow coin={{ currency: item.currency }} />
                            <Text tid={'amount'} />
                            <span>{formatNumber(item.amount, { type: item.currency })}</span>
                            <Text tid={'txId'} />
                            <div className={'flex items-center gap-2'}>
                                <span className={'max-w-[200px] truncate'}>{item.txId}</span>
                                <div className={'cursor-pointer'} onClick={() => copyToClip(item.txId)}>
                                    <FiCopy size={20} />
                                </div>
                            </div>
                            <MiniLineBreak />
                            <MiniLineBreak />
                        </div>
                    </Row>
                )
            })}
        </RTableLayout>
    )
}

export default RResyncPaymentTable
