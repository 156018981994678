import {useNavigate} from "react-router-dom";
import {repositoryAttributes} from "../../../../core/constants/headers";
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex";
import {useCopyToClipboard} from "../../../../core/hooks/common/useCopyToClipboard";
import TableLayout from "../../../layout/main/TableLayout";
import {Column, Flex, Relative, Row} from "../../../../styles/CommonStyles";
import Address from "../../../common/common/Address";
import Tooltip from "../../../common/utils/Tooltip";
import {CopyIcon, ShowIcon} from "../../../../styles/main/reports";
import CoinRow from "../../../common/common/CoinRow";
import {formatNumber} from "../../../../core/utils/common";
import {TradeTypeBadge} from "../../../../styles/main/orders";
import BooleanIcon from "../../../common/utils/BooleanIcon";
import DateTime from "../../../common/utils/DateTime";
import RepoBalanceView from "../transactions/RepoBalanceView";


const RepositoriesTable = ({ data }) => {

    const navigate = useNavigate()
    const { data: transactions } = data
    const { headers, cs } = repositoryAttributes
    const { getTableIndex } = useGetTableIndex()

    const { copyToClip } = useCopyToClipboard()

    // const onDetailsClicked = (trans) => {
    //     navigate(`/reports/transactions/${trans._id}`)
    // }

    return (
        <TableLayout
            data={data}
            cs={cs}
            headers={headers}
        >
            {transactions?.data?.map((item, idx) => (
                <Relative key={item._id}>
                    <Row index={idx} cs={cs}>
                        <Column>
                            {getTableIndex(idx)}
                        </Column>
                        <Column>
                            <Flex>
                                <Address address={item.address} />
                                <Tooltip content={item.address}>
                                    <div>
                                        <ShowIcon size={20} />
                                    </div>
                                </Tooltip>
                                <Tooltip content={'copy-address'}>
                                    <div onClick={() => copyToClip(item.address)}>
                                        <CopyIcon size={20} />
                                    </div>
                                </Tooltip>
                            </Flex>
                        </Column>
                        <Column>
                            <RepoBalanceView balance={item.balance} />
                        </Column>
                        <Column>
                            <TradeTypeBadge>
                                {item.network}
                            </TradeTypeBadge>
                        </Column>
                        <Column center>
                            <BooleanIcon bool={item.inUse} />
                        </Column>
                        <Column center>
                            <BooleanIcon bool={item.isDelete} />
                        </Column>
                        <Column center>
                            <DateTime dt={item.usedAt} />
                        </Column>
                        <Column center>
                            <DateTime dt={item.createdAt} />
                        </Column>
                    </Row>
                    {/*<Column operation>*/}
                    {/*    <Operation*/}
                    {/*        onDetailsClicked={() => onDetailsClicked(item)}*/}
                    {/*        hasDetails*/}
                    {/*    />*/}
                    {/*</Column>*/}
                </Relative>
            ))}
        </TableLayout>
    )
}

export default RepositoriesTable
