import {useQuery} from "react-query";
import {filterFetch} from "../../../fetch-api/get";
import {useMainContext} from "../../../../contexts/main";
import {makeQueryFilters} from "../../../../utils/query-maker";


export const useRepositoriesList = (filters, pagination, extra, csv) => {

    const { lang } = useMainContext()
    const newFilters = {...filters, ...extra}
    const params = {...makeQueryFilters(newFilters, lang), ...pagination}
    if (csv){
        params.export = 'csv'
    }

    return useQuery(
        'repositories-list', () => filterFetch(params, 'repositories?order=1')
    )
}
