import {useMainContext} from "../../../../../core/contexts/main";
import {useCopyToClipboard} from "../../../../../core/hooks/common/useCopyToClipboard";
import RTableLayout from "../../../layout/RTableLayout";
import {CMargin, DText, Flex, Row} from "../../../../../styles/CommonStyles";
import Operation from "../../../../common/utils/Operation";
import Text from "../../../../../core/utils/Text";
import Address from "../../../../common/common/Address";
import {CopyIcon} from "../../../../../styles/main/reports";
import {LineBreak} from "../../../../../styles/main/MainCommonStyles";
import CoinRow from "../../../../common/common/CoinRow";
import {TradeTypeBadge} from "../../../../../styles/main/orders";
import BooleanIcon from "../../../../common/utils/BooleanIcon";
import {formatDate} from "../../../../../core/utils/common";
import RepoBalanceView from "../../../../main/reports/transactions/RepoBalanceView";


const RRepositoriesTable = ({ data }) => {

    // const navigate = useNavigate()
    const { lang } = useMainContext()
    const { data: repositories } = data
    const { copyToClip } = useCopyToClipboard()

    // const onDetailsClicked = (trans) => {
    //     navigate(`/reports/transactions/${trans._id}`)
    // }


    return (
        <RTableLayout
            data={data}
        >
            {repositories?.data?.map((item, idx) => (
                <Row key={item._id} index={idx} cs={'100%'}>
                    {/*<Flex>*/}
                    {/*    <div />*/}
                    {/*    <Operation*/}
                    {/*        onDetailsClicked={() => onDetailsClicked(item)}*/}
                    {/*        hasDetails*/}
                    {/*    />*/}
                    {/*</Flex>*/}
                    <CMargin margin={'2px'} />
                    <Flex fw justify={'space-between'}>
                        <DText fontSize={'ss'} main>
                            <Text tid={'address'} /> :
                        </DText>
                        <Flex>
                            <Address address={item.address} style={{ margin: '0 8px' }} />
                            <CopyIcon size={18} onClick={() => copyToClip(item.address)} />
                        </Flex>
                    </Flex>
                    <LineBreak />
                    <Flex justify={'space-between'}>
                        <DText fontSize={'ss'} main style={{ width: '100px' }}>
                            <Text tid={'balance'} /> :
                        </DText>
                        <RepoBalanceView balance={item.balance} />
                    </Flex>
                    <LineBreak />
                    <Flex justify={'space-between'}>
                        <DText fontSize={'ss'} main>
                            <Text tid={'network'} /> :
                        </DText>
                        <TradeTypeBadge>
                            {item.network}
                        </TradeTypeBadge>
                    </Flex>
                    <LineBreak />
                    <Flex justify={'space-between'}>
                        <DText fontSize={'ss'} main>
                            <Text tid={'inUse'} /> :
                        </DText>
                        <BooleanIcon bool={item.inUse} />
                    </Flex>
                    <LineBreak />

                    <Flex justify={'space-between'}>
                        <DText fontSize={'ss'} main>
                            <Text tid={'isDelete'} /> :
                        </DText>
                        <BooleanIcon bool={item.isDelete} />
                    </Flex>
                    <LineBreak />
                    <DText main>
                        <Text tid={'usedAt'} /> :
                    </DText>
                    <Flex justify={'space-between'}>
                        <DText fontSize={'ss'} main>
                            {formatDate(item.usedAt, 'date', lang)}
                        </DText>
                        <DText fontSize={'ss'} main>
                            {formatDate(item.usedAt, 'time', lang)}
                        </DText>
                    </Flex>
                    <LineBreak />
                    <DText main>
                        <Text tid={'createdAt'} /> :
                    </DText>
                    <Flex justify={'space-between'}>
                        <DText fontSize={'ss'} main>
                            {formatDate(item.createdAt, 'date', lang)}
                        </DText>
                        <DText fontSize={'ss'} main>
                            {formatDate(item.createdAt, 'time', lang)}
                        </DText>
                    </Flex>
                </Row>
            ))}
        </RTableLayout>
    )
}

export default RRepositoriesTable
