import styled from "styled-components";
import {HiStar} from "react-icons/hi"

const UserStars = ({
    count
}) => {


    return (
        <Wrapper>
            {Array.from(Array(count).keys()).map(item => (
                <HiStar key={item} color={'#f0b90b'} size={Math.min(100 / count, 16)}/>
            ))}
        </Wrapper>
    )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`


export default UserStars
