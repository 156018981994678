import {DText, Flex} from "../../../../styles/CommonStyles";
import {PermTagWrapper, ReadIcon, WriteIcon} from "../../../../styles/main/setting";
import {getRandomColor} from "../../../../core/utils/theme";
import Text from "../../../../core/utils/Text";


const PermissionsColumn = ({
    perms
}) => {

    return (
        <Flex fw wrap>
            {perms.map((perm, idx) => (
                <PermTagWrapper
                    color={getRandomColor(idx)}
                    active={perm.activity.write || perm.activity.read}
                    key={idx}
                >
                    <Flex fw fh justify={'space-between'}>
                        <DText main fontSize={'ss'} style={{ margin: '0 4px' }}>
                            <Text tid={`${perm.scope}-perm`} />
                        </DText>
                        {(perm.activity.write || perm.activity.read) &&
                            <Flex>
                                {perm.activity.read &&
                                    <ReadIcon size={16} />
                                }
                                {perm.activity.write &&
                                    <WriteIcon size={16} />
                                }
                            </Flex>
                        }
                    </Flex>
                </PermTagWrapper>
            ))}
        </Flex>
    )
}

export default PermissionsColumn
