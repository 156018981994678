import styled from "styled-components";
import {CFlex, Column, Flex, Row} from "../../../../styles/CommonStyles";
import Text from "../../../../core/utils/Text";
import {ScaleLoader} from "react-spinners";


const UserStatsTable = ({
    data
}) => {

    if (!data) return (
        <Flex fw height={'300px'}>
            <ScaleLoader
                color={'#9F9181'}
                height={26}
                width={3}
            />
        </Flex>
    )

    return (
        <CFlex fw>
            {data?.map(item => (
                <UserStatsRow cs={CS} key={item.name}>
                    <Column>
                        <Text tid={item.name} />
                    </Column>
                    <Column>
                        {item.value}
                    </Column>
                </UserStatsRow>
            ))}
        </CFlex>
    )
}

const CS = '50% 50%'

const UserStatsRow = styled(Row)`
  padding: 10px;
  min-height: 32px;
`

export default UserStatsTable
