import {UserActionWrapper, UserDetailListWrapper, UserSettingBtn} from "../../../../../styles/main/user";
import {Flex} from "../../../../../styles/CommonStyles";
import Text from "../../../../../core/utils/Text";
import {useState} from "react";
import {useLocation} from "react-router-dom";
import ChangeBalance from "./ChangeBalance";
import BlockAccess from "./BlockAccess";
import BlockedStatus from "../../../setting/general/blocked-status/BlockedStatus";


const UserSetting = (props) => {

    const location = useLocation()
    console.log(location)

    const navigateActions = {
        'increase-balance': 0,
        'decrease-balance': 1
    }

    const [active, setActive] = useState(
        navigateActions[location.state?.action] || -1
    )

    const actions = [
        // { name: 'accept-user', color: '#819F82' },
        // { name: 'reject-user', color: '#9F819E' },
        { name: 'increase-balance', color: '#818F9F' },
        { name: 'decrease-balance', color: '#82819F' },
        // { name: 'send-notification', color: '#818F9F' },
        // { name: 'remove-2fa', color: '#9F9181' },
        { name: 'block-access', color: '#9F8281' }
    ]


    console.log(active)
    const comps = [
        // AcceptUser,
        // RejectUser,
        ChangeBalance,
        ChangeBalance,
        // SendNotification,
        // Remove2fa,
        BlockedStatus
    ]

    const UserAction = comps[active]

    return (
        <UserDetailListWrapper>
            <Flex fw wrap>
                {actions.map((action, idx) => (
                    <UserSettingBtn
                        color={action.color}
                        onClick={() => setActive(idx)}
                        active={idx === active}
                        key={idx}
                    >
                        <Flex fw fh>
                            <Text tid={action.name} />
                        </Flex>
                    </UserSettingBtn>
                ))}
            </Flex>
            {active !== -1 &&
                <UserActionWrapper>
                    <UserAction
                        {...props}
                        changeType={active === 1 ? 'decrease' : 'increase'}
                        isUser
                    />
                </UserActionWrapper>
            }
        </UserDetailListWrapper>
    )
}

export default UserSetting
