import {Background} from "../../../../../styles/CommonStyles";
import Input from "../../../../common/input/Input";
import {useGetStaticPrice} from "../../../../../core/services/react-query/setting/static-price";
import {useState, useEffect} from "react";


const StaticPrice = ({ coin }) => {

    const [payload, setPayload] = useState({})
    const { data: staticPrice } = useGetStaticPrice(coin?.currency)

    useEffect(() => {
        if (staticPrice?.length) setPayload(staticPrice[0])
        else setPayload({})
    }, [staticPrice])

    return (
        <div className={'grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-10 mt-5'}>
            <Background bg='detailBox'>
                <Input
                    size={'small'}
                    className={'w-full lg:w-[90%] h-[48px]'}
                    currency={coin?.currency}
                    value={payload.buy || 0}
                    number={true}
                    label={'static-buy-price'}
                    disabled
                />
            </Background>
            <Background bg='detailBox'>
                <Input
                    size={'small'}
                    className={'w-full lg:w-[90%] h-[48px]'}
                    currency={coin?.currency}
                    value={payload.sell || 0}
                    number={true}
                    label={'static-sell-price'}
                    disabled
                />
            </Background>
        </div>
    )
}

export default StaticPrice
