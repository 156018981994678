import Tooltip from "./Tooltip";
import {DText} from "../../../styles/CommonStyles";


const NameTag = ({ name, size= 20, fontSize = 's' }) => {

    return (
        <>
            {name.length > size ?
                <Tooltip
                    content={name}
                >
                    <DText main fontSize={fontSize} style={{ cursor: 'pointer' }}>
                        {name.substring(0, 20)}
                    </DText>
                </Tooltip>
                :
                <DText main fontSize={fontSize}>
                    {name}
                </DText>
            }
        </>

    )
}

export default NameTag
