

export const workspaceAttributes = {
    cs: '10% 25% 15% 15% 10% 10% 15%',
    headers: [
        { title: 'user' },
        { title: 'name' },
        { title: 'ip' },
        { title: 'category' },
        { title: 'is-active' },
        { title: 'date', sort: 'createdAt' }
    ]
}

export const withdrawListAttributes = {
    cs: '5% 30% 15% 15% 15% 15% 5%',
    headers: [
        { title: 'creator' },
        { title: 'currency' },
        { title: 'total' },
        { title: 'withdraw-status' },
        { title: 'date' }
    ]
}

export const withdrawDetailAttributes = {
    cs: '40% 20% 20% 20%',
    headers: [
        { title: 'user' },
        { title: 'has-approve' },
        { title: 'approved' },
        { title: 'share' },
    ]
}

export const contractAttributes = {
    cs: '5% 25% 15% 10% 10% 10% 10% 10%',
    headers: [
        { title: 'name' },
        { title: 'address' },
        { title: 'currency' },
        { title: 'amount', sort: 'amount' },
        { title: 'network'},
        { title: 'type'},
        { title: 'date', sort: 'createdAt' }
    ]
}

export const businessContractAttributes = {
    cs: '5% 20% 15% 10% 10% 10% 15% 10%',
    headers: [
        { title: 'address' },
        { title: 'currency' },
        { title: 'amount', sort: 'amount' },
        { title: 'network'},
        { title: 'type'},
        { title: 'status' },
        { title: 'date', sort: 'createdAt' }
    ]
}

export const transactionAttributes = {
    cs: '5% 18% 18% 14% 10% 10% 10% 10% 5%',
    headers: [
        { title: 'fromAddress' },
        { title: 'toAddress' },
        { title: 'currency' },
        { title: 'amount', sort: 'amount' },
        { title: 'network'},
        { title: 'hasPayment'},
        { title: 'date', sort: 'createdAt' }
    ]
}

export const repositoryAttributes = {
    cs: '5% 20% 25% 10% 10% 10% 10% 10%',
    headers: [
        { title: 'address' },
        { title: 'balance' },
        { title: 'network'},
        { title: 'inUse'},
        { title: 'isDelete'},
        { title: 'usedAt', sort: 'usedAt' },
        { title: 'date', sort: 'createdAt' }
    ]
}

export const paymentAttributes = {
    cs: '5% 20% 10% 10% 10% 10% 10% 10% 15%',
    headers: [
        { title: 'name' },
        { title: 'address' },
        { title: 'currency' },
        { title: 'amount', sort: 'amount' },
        { title: 'network'},
        { title: 'type'},
        { title: 'status' },
        { title: 'date', sort: 'createdAt' }
    ]
}

export const businessPaymentAttributes = {
    cs: '5% 20% 12% 12% 12% 12% 11% 15%',
    headers: [
        { title: 'address' },
        { title: 'currency' },
        { title: 'amount', sort: 'amount' },
        { title: 'network'},
        { title: 'type'},
        { title: 'status' },
        { title: 'date', sort: 'createdAt' }
    ]
}

export const otcOrdersAttributes = {
    cs: '4% 15% 10% 10% 12% 12% 12% 10% 10% 5%',
    headers: [
        { title: 'name' },
        { title: 'coin' },
        { title: 'amount', sort: 'amount' },
        { title: 'wage', sort: 'wage' },
        { title: 'unitPrice', sort: 'priceUnit' },
        { title: 'price', sort: 'price' },
        { title: 'transaction-type' },
        { title: 'traded-date', sort: 'tradedAt' }
    ]
}

export const tradeAttributes = {
    cs: '5% 14% 14% 10% 8% 10% 10% 8% 8% 8% 5%',
    headers: [
        {title: 'maker'},
        {title: 'taker'},
        {title: 'market'},
        {title: 'trade-type'},
        {title: 'amount', sort: 'amount'},
        {title: 'unitPrice', sort: 'priceUnit'},
        {title: 'price', sort: 'price'},
        {title: 'transaction-type'},
        {title: 'date', sort: 'createdAt'}
    ]
}

export const userAttributes = {
    cs: '5% 20% 15% 15% 15% 15% 10% 5%',
    headers: [
        {title: 'email'},
        {title: 'user-code'},
        {title: 'type' },
        {title: 'role'},
        {title: 'ga'},
        {title: 'user-is-active'}
    ]
}


export const cartableAttributes = {
    cs: '5% 15% 20% 15% 15% 15% 10% 5%',
    headers: [
        { title: 'currency' },
        { title: 'askById' },
        { title: 'volume' },
        { title: 'action' },
        { title: 'status' },
        { title: 'date', sort: 'createdAt' }
    ]
}

export const bankAccountAttributes = {
    cs: '5% 20% 10% 22% 30% 8% 5%',
    headers: [
        { title: 'fullname' },
        { title: 'user-status' },
        { title: 'card-number' },
        { title: 'sheba-number' },
        { title: 'bank-label' }
    ]
}

export const fileAttributes = {
    cs: '5% 40% 18% 18% 12% 8%',
    headers: [
        { title: 'file-name' },
        { title: 'file-type' },
        { title: 'file-status' },
        { title: 'date', sort: 'createdAt' },
    ]
}

export const coinTransactionAttributes = {
    cs: '5% 15% 15% 15% 15% 15% 15% 5%',
    headers: [
        { title: 'name' },
        { title: 'coin' },
        { title: 'amount', sort: 'amount' },
        { title: 'transaction-type' },
        { title: 'transaction-status' },
        { title: 'date', sort: 'createdAt' },
    ]
}


export const bankTransactionAttributes = {
    cs: '5% 20% 17% 17% 16% 20% 5%',
    headers: [
        {title: 'name'},
        {title: 'amount', sort: 'amount'},
        {title: 'transaction-type'},
        {title: 'transaction-status'},
        {title: 'date', sort: 'createdAt'},
    ]
}


export const wagesAttributes = {
    cs: '5% 25% 25% 20% 20% 5%',
    headers: [
        { title: 'coin' },
        { title: 'count' },
        { title: 'amount' },
        { title: 'wages' }
    ]
}

export const traceAttributes = {
    cs: '5% 20% 10% 10% 10% 10% 10% 10% 15%',
    headers: [
        { title: 'entityId' },
        { title: 'entityType' },
        { title: 'currency' },
        { title: 'amount', sort: 'amount' },
        { title: 'wage', sort: 'wage' },
        { title: 'cause' },
        { title: 'action' },
        { title: 'date', sort: 'createdAt' },
    ]
}


export const affiliateListAttribute = {
    cs: '5% 15% 12% 12% 11% 25% 12% 8%',
    headers: [
        { title: 'name' },
        { title: 'affiliate-code' },
        { title: 'use-count', sort: 'used' },
        { title: 'max-use', sort: 'maxUse' },
        { title: 'share' },
        { title: 'date', sort: 'createdAt' },
    ]
}


export const affiliateTraceAttribute = {
    cs: '5% 20% 35% 35% 5%',
    headers: [
        { title: 'coin' },
        { title: 'actor-share' },
        { title: 'object-share' },
    ]
}

export const addressAttributes = {
    cs: '5% 25% 10% 15% 15% 10% 10% 10%',
    headers: [
        { title: 'user' },
        { title: 'name' },
        { title: 'address' },
        { title: 'coin' },
        { title: 'network' },
        { title: 'memo' },
        { title: 'date', sort: 'createdAt' },
    ]
}

export const availableBanksAttributes = {
    cs: '5% 25% 25% 25% 20%',
    headers: [
        { title: 'symbol' },
        { title: 'en-name' },
        { title: 'fa-name' },
        { title: 'ab-status' },
    ]
}

export const thirdBalanceAttributes = {
    cs: '5% 20% 20% 20% 20% 15%',
    headers: [
        { title: 'coin' },
        { title: 'type' },
        { title: 'balance' },
        { title: 'available' },
        { title: 'holds' },
    ]
}


export const pairAttributes = {
    cs: '5% 30% 30% 30% 5%',
    headers: [
        { title: 'coin' },
        { title: 'en-name' },
        { title: 'fa-name' }
    ]
}


export const roleAttributes = {
    cs: '5% 10% 70% 10% 5%',
    headers: [
        { title: 'name', sort: 'name' },
        { title: 'permissions' },
        { title: 'date', sort: 'createdAt' }
    ]
}


export const tokenAttributes = {
    cs: '5% 15% 15% 15% 15% 15% 15% 5%',
    headers: [
        { title: 'token' },
        { title: 'blockchain', sort: 'name' },
        { title: 'network' },
        { title: 'decimal', sort: 'decimal' },
        { title: 'is-active' },
        { title: 'is-available' }
    ]
}

export const availableCoinsWDListAttributes = {
    cs: "4% 10% 10% 13% 10% 10% 10% 10% 8% 8% 7%",
    headers: [
        { title: "name" },
        { title: "network" },
        { title: "min" },
        { title: "max" },
        { title: "fee-factor" },
        { title: "fee-min" },
        { title: "fee-max" },
        { title: "delay" },
        { title: "color" },
        { title: "isActive" }
    ]
}

export const resyncListAttributes = {
    cs: "4% 15% 15% 60%",
    headers: [
        { title: "currency" },
        { title: "amount" },
        { title: "txid" },
    ]
}
