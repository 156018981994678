import {useQuery} from "react-query";
import {useMainContext} from "../../../../contexts/main";
import {makeQueryFilters} from "../../../../utils/query-maker";
import {filterFetch, normalFetch} from "../../../fetch-api/get";


export const CONTRACT_KEYS = {
    LIST_CONTRACTS: 'list-contracts',
    GET_WALLET_TRANSACTION: 'get-wallet-transaction'
}


const useListWalletTransactions = (filters, pagination, extra, csv) => {

    const { lang } = useMainContext()
    const newFilters = {...filters, ...extra}
    const params = {...makeQueryFilters(newFilters, lang), ...pagination}
    if (csv){
        params.export = 'csv'
    }

    return useQuery(
        CONTRACT_KEYS.LIST_CONTRACTS, () => filterFetch(params, 'wallets/admin')
    )
}

const useGetWalletTransaction = (id) => {

    return useQuery(
        [CONTRACT_KEYS.GET_WALLET_TRANSACTION, id], () => normalFetch(`wallets/admin/${id}`),
        {
            select: res => res?.data?.data,
            enabled: !!id
        }
    )
}

export {
    useListWalletTransactions,
    useGetWalletTransaction
}
