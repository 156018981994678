import {useNavigate} from "react-router-dom";
import {transactionAttributes} from "../../../../core/constants/headers";
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex";
import {useCopyToClipboard} from "../../../../core/hooks/common/useCopyToClipboard";
import TableLayout from "../../../layout/main/TableLayout";
import {Column, Flex, Relative, Row} from "../../../../styles/CommonStyles";
import Address from "../../../common/common/Address";
import Tooltip from "../../../common/utils/Tooltip";
import {CopyIcon, ShowIcon} from "../../../../styles/main/reports";
import CoinRow from "../../../common/common/CoinRow";
import {formatNumber} from "../../../../core/utils/common";
import {TradeTypeBadge} from "../../../../styles/main/orders";
import DateTime from "../../../common/utils/DateTime";
import BooleanIcon from "../../../common/utils/BooleanIcon";
import Operation from "../../../common/utils/Operation";


const TransactionsTable = ({ data }) => {

    const navigate = useNavigate()
    const { data: transactions } = data
    const { headers, cs } = transactionAttributes
    const { getTableIndex } = useGetTableIndex()

    const { copyToClip } = useCopyToClipboard()

    const onDetailsClicked = (trans) => {
        navigate(`/reports/transactions/${trans._id}`)
    }

    return (
        <TableLayout
            data={data}
            cs={cs}
            headers={headers}
        >
            {transactions?.data?.map((item, idx) => (
                <Relative key={item._id}>
                    <Row index={idx} cs={cs}>
                        <Column>
                            {getTableIndex(idx)}
                        </Column>
                        <Column>
                            <Flex>
                                <Address address={item.fromAddress} />
                                <Tooltip content={item.fromAddress}>
                                    <div>
                                        <ShowIcon size={20} />
                                    </div>
                                </Tooltip>
                                <Tooltip content={'copy-address'}>
                                    <div onClick={() => copyToClip(item.fromAddress)}>
                                        <CopyIcon size={20} />
                                    </div>
                                </Tooltip>
                            </Flex>
                        </Column>
                        <Column>
                            <Flex>
                                <Address address={item.toAddress} />
                                <Tooltip content={item.toAddress}>
                                    <div>
                                        <ShowIcon size={20} />
                                    </div>
                                </Tooltip>
                                <Tooltip content={'copy-address'}>
                                    <div onClick={() => copyToClip(item.toAddress)}>
                                        <CopyIcon size={20} />
                                    </div>
                                </Tooltip>
                            </Flex>
                        </Column>
                        <Column>
                            <CoinRow coin={{ currency: item.currency }} />
                        </Column>
                        <Column>
                            {formatNumber(item.amount, { type: item.currency })}
                        </Column>
                        <Column>
                            <TradeTypeBadge>
                                {item.network}
                            </TradeTypeBadge>
                        </Column>
                        <Column center>
                            <BooleanIcon bool={item.hasPayment} />
                        </Column>
                        <Column center>
                            <DateTime dt={item.createdAt} />
                        </Column>
                    </Row>
                    <Column operation>
                        <Operation
                            onDetailsClicked={() => onDetailsClicked(item)}
                            hasDetails
                        />
                    </Column>
                </Relative>
            ))}
        </TableLayout>
    )
}

export default TransactionsTable
