import {useMutation, useQuery} from "react-query";
import {normalFetch} from "../../fetch-api/get";
import {useMainContext} from "../../../contexts/main";
import {useQueryContext} from "../../../contexts/query";
import {putApi} from "../../fetch-api/put";
import {deleteApi} from "../../fetch-api/delete";


export const PROFILE_KEYS = {
    GET_PROFILE: 'get-profile',
    UPDATE_PROFILE: 'update_profile',
    UPDATE_PROFILE_AVATAR: 'update-profile-avatar',
    DELETE_PROFILE_AVATAR: 'delete-profile-avatar'
}


const useProfileQuery = (onSuccess) => {

    const { token, setToken } = useMainContext()

    return useQuery(
        PROFILE_KEYS.GET_PROFILE, () => normalFetch('users/self'),{
            staleTime: 30 * 60 * 1000,
            cacheTime: 30 * 60 * 1000,
            select: res => res?.data?.data,
            enabled: !!token,
            retry: 0,
            onSuccess,
            onError: () => {
                setToken('')
            }
        }
    )
}


const useUpdateProfile = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        PROFILE_KEYS.UPDATE_PROFILE, (data) => putApi(data, 'profile'), {
        onSuccess: () => {

            queryClient.invalidateQueries(PROFILE_KEYS.GET_PROFILE)
            setToast({
                isError: false, show: true,
                message: "operation-success"
            })
        },
    })
}


const useUpdateProfileAvatar = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        PROFILE_KEYS.UPDATE_PROFILE_AVATAR, (data) => putApi(data, 'profile/avatar'), {
        onSuccess: () => {
            queryClient.invalidateQueries(PROFILE_KEYS.GET_PROFILE)
            setToast({
                isError: false, show: true,
                message: "image-update"
            })
        },
    })
}


const useDeleteProfileAvatar = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        PROFILE_KEYS.DELETE_PROFILE_AVATAR, () => deleteApi('profile/avatar'), {
        onSuccess: () => {
            queryClient.invalidateQueries(PROFILE_KEYS.GET_PROFILE)
            setToast({
                isError: false, show: true,
                message: "image-delete"
            })
        },
    })
}


export {
    useProfileQuery,
    useUpdateProfile,
    useUpdateProfileAvatar,
    useDeleteProfileAvatar
}
