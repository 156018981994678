import {useQuery} from "react-query";
import {filterFetch, normalFetch} from "../../../fetch-api/get";
import {useMainContext} from "../../../../contexts/main";
import {makeQueryFilters} from "../../../../utils/query-maker";


export const useTransactionsList = (filters, pagination, extra, csv) => {

    const { lang } = useMainContext()
    const newFilters = {...filters, ...extra}
    const params = {...makeQueryFilters(newFilters, lang), ...pagination}
    if (csv){
        params.export = 'csv'
    }

    return useQuery(
        'transactions-list', () => filterFetch(params, 'transactions')
    )
}

export const useGetATransaction = (id) => {

    return useQuery(
        ['get-transaction', id], () => normalFetch(`transactions/${id}`),
        {
            enabled: !!id,
            select: res => res?.data?.data
        }
    )
}
