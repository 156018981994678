import { useMutation, useQuery } from "react-query";
import {normalFetch, getPing} from "../../fetch-api/get";
import { putApi } from '../../fetch-api/put'
import { useQueryContext } from "../../../contexts/query"
import {postApi} from "../../fetch-api/post";
import useAsyncError from "../../../hooks/common/useAsyncError";


const SETTING_KEYS = {
    CURRENCIES: 'currencies',
    SET_CURRENCIES: 'set-currencies',
    MARKET: 'market',
    GET_CONFIG_ITEMS: 'get-config-items',
    SET_CONFIG_ITEMS: 'set-config-items',
    GET_THIRD_CONFIG: 'get-third-config',
    MOVE_T0_MASTER: 'move-to-master',
    SET_COIN_STATIC: 'set-coin-static',
    RECOVER_PAYMENT: 'recover-payment',
    blockedStatus: 'blockedStatus',
    setBlockedStatus: 'setBlockedStatus'
}


const useCurrencies = () => {

    return useQuery(
        SETTING_KEYS.CURRENCIES, () => normalFetch('settings/currency'),
        {
            staleTime: 5 * 60 * 1000,
            cacheTime: 5 * 60 * 1000,
            select: res => res?.data?.data,
        }
    )
}


const useSetCurrencies = (onSuccess) => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        SETTING_KEYS.SET_CURRENCIES, (data) => putApi({ data }, 'settings/currency'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(SETTING_KEYS.CURRENCIES)
                onSuccess && onSuccess()
                setToast({
                    show: true, message: 'operation-success'
                })
            }
        }
    )
}


const useGetConfigItems = () => {

    return useQuery(
        SETTING_KEYS.GET_CONFIG_ITEMS, () => normalFetch('settings/items'),
        {
            select: res => res?.data?.data,
            staleTime: 5 * 60 * 1000,
            cacheTime: 5 * 60 * 1000
        }
    )
}


const useSetConfigItems = () => {

    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        SETTING_KEYS.SET_CONFIG_ITEMS, (data) => putApi(data,'settings/items'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(SETTING_KEYS.GET_CONFIG_ITEMS)
                setToast({
                    show: true, message: 'operation-success'
                })
            }
        }
    )
}


const useGetThirdConfig = () => {

    return useQuery(
        SETTING_KEYS.GET_THIRD_CONFIG, () => normalFetch('configs/settings/item'),
        {
            select: res => res?.data?.data?.third,
            staleTime: 30 * 60 * 1000,
            cacheTime: 30 * 60 * 1000
        }
    )
}


const useMoveToMaster = () => {

    const { setToast } = useQueryContext()

    return useMutation(
        SETTING_KEYS.MOVE_T0_MASTER, (data) => postApi(data, 'settings/wallet'),
        {
            onSuccess: () => {
                setToast({
                    message: 'operation-success',
                    show: true
                })
            }
        }
    )
}


const useSetCoinStaticPrices = () => {

    return useMutation(
        SETTING_KEYS.SET_COIN_STATIC, (data) => putApi(data, 'settings/coins/price')
    )
}

const useMarket = () => {

    return useQuery(
        SETTING_KEYS.MARKET, () => normalFetch('market'),
        {
            select: res => res?.data?.data
        }
    )
}

export const useRecoverPayments = () => {

    const throwError = useAsyncError()

    return useMutation(
        SETTING_KEYS.RECOVER_PAYMENT, ({ payload, paymentId }) => postApi(payload, `payments/admin/recover/${paymentId}`, throwError)
    )
}

export const useGetBlockedStatus = () => {

    return useQuery(
        SETTING_KEYS.blockedStatus, () => normalFetch('settings/blockedScopes'),
        {
            select: res => res?.data?.data
        }
    )
}

export const useSetBlockedStatus = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        SETTING_KEYS.setBlockedStatus, (payload) => putApi(payload, 'settings/blockedScopes'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([SETTING_KEYS.blockedStatus])
                setToast({
                    message: 'operation-success',
                    show: true
                })
            }
        }
    )
}


export {
    useCurrencies,
    useSetCurrencies,
    useGetConfigItems,
    useSetConfigItems,
    useGetThirdConfig,
    useMoveToMaster,
    useMarket,
    useSetCoinStaticPrices
}
