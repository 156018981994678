import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {useState} from "react";
import {TABLET_SIZE} from "../../../core/constants/common";
import ListLayout from "../../../components/layout/main/ListLayout";
import {PageCard} from "../../../styles/CommonStyles";
import FilterLayout from "../../../components/layout/filter/FilterLayout";
import {
    CacheKeys,
    paymentFilterOptions, paymentSortOptions
} from "../../../core/constants/filter";
import {useListPayments} from "../../../core/services/react-query/report/payment";
import PaymentTable from "../../../components/main/reports/payment/PaymentTable";
import RPaymentTable from "../../../components/responsive/main/reports/payment/RPaymentTable";


const Payment = () => {

    const { width } = useWindowSize()

    const [payments, setPayments] = useState({loading: true, data: null})
    const onPaymentQuery = res => setPayments(res)

    const Component = width > TABLET_SIZE ? PaymentTable : RPaymentTable

    return (
        <ListLayout>
            <PageCard>
                <FilterLayout
                    query={useListPayments}
                    options={paymentFilterOptions}
                    onQuerySuccess={onPaymentQuery}
                    cache={CacheKeys.PAYMENT}
                    sortOptions={paymentSortOptions}
                >
                    <Component data={payments} />
                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}

export default Payment
