import SubmitModal from "../../../modals/SubmitSettingModal";
import {Background, CFlex, CMargin, Column, Flex, Row} from "../../../../styles/CommonStyles";
import Text from "../../../../core/utils/Text";
import CheckBox from "../../../common/input/CheckBox";
import {useEffect, useState} from "react";
import Input from "../../../common/input/Input";


const RolesUpdate = ({
    role,
    onPermissionChange,
    exitEditMode,
    onSubmitChanges,
    loading
}) => {

    const [readAll, setReadAll] = useState(false)
    const [writeAll, setWriteAll] = useState(false)

    useEffect(() => {
        let _readAll = true
        let _writeAll = true
        if (role) {
            const permissions = role.permissions
            for (let i = 0; i < permissions.length; i++) {
                const perm = permissions[i]
                if (!perm.activity.read) {
                    _readAll = false
                }
                if (!perm.activity.write) {
                    _writeAll = false
                }
            }
            setReadAll(_readAll)
            setWriteAll(_writeAll)
        }
    }, [role])


    return (
        <CFlex fw align={'flex-start'}>
            <CMargin margin={'15px'} />
            <Background bg={'mainBg'}>
                <Input
                    value={role.name}
                    onInputChange={(v) => onPermissionChange('role-name', v)}
                    label={'role-name'}
                    width={'300px'}
                    height={'42px'}
                    valid={!!role.name}
                    error={'name-required'}
                />
            </Background>

            <CMargin margin={'8px'} />
            <Row cs={CS} noHover header>
                {headers.map(head => (
                    <Column key={head}>
                        {head === 'permission' ?
                            <Text tid={head} />
                            :
                            <Flex>
                                <CheckBox
                                    active={
                                        head === 'read-access' ?
                                            readAll : (
                                                head === 'write-access' ?
                                                    writeAll
                                                    :
                                                    readAll && writeAll
                                            )
                                    }
                                    onClick={() => onPermissionChange(head, null, { readAll, writeAll })}
                                />
                                <Text style={{ margin: '0 5px' }} tid={head} />
                            </Flex>
                        }
                    </Column>
                ))}
            </Row>

            {role?.permissions?.map((perm, idx) => (
                <Row index={idx} cs={CS} noHover key={idx}>
                    <Column>
                        <Text tid={`${perm.scope}-perm`} />
                    </Column>
                    <Column>
                        <CheckBox
                            active={perm.activity.read}
                            onClick={() => onPermissionChange('read', idx)}
                        />
                    </Column>
                    <Column>
                        <CheckBox
                            active={perm.activity.write}
                            onClick={() => onPermissionChange('write', idx)}
                        />
                    </Column>
                    <Column>
                        <CheckBox
                            active={perm.activity.write && perm.activity.read}
                            onClick={() => onPermissionChange('read-write', idx)}
                        />
                    </Column>
                </Row>
            ))}
            <SubmitModal
                editMode
                onSubmit={onSubmitChanges}
                onCancel={exitEditMode}
                loading={loading}
            />
        </CFlex>
    )
}

const CS = '25% 25% 25% 25%'
const headers = [
    'permission', 'read-access', 'write-access', 'read-write-access'
]

export default RolesUpdate
