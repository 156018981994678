
export const CacheKeys = {
    WORKSPACE: 'workspace',
    WITHDRAW: 'withdraw',
    WALLET_TRANSACTIONS: 'wallet-transactions',
    USER: 'user',
    TRADE: 'trade',
    PAYMENT: 'payment',
    COIN_TRANSACTIONS: 'coin-transactions',
    BANK_TRANSACTIONS: 'bank-transactions',
    COIN_TRACE: 'coin-trace',
    AFFILIATE_LIST: 'affiliate-list',
    AFFILIATE_TRACE: 'affiliate-trace',
    TOKENS: 'tokens',
    ROLES: 'roles',
    FILES: 'files',
    USER_ORDERS: 'user-orders',
    USER_TRADES: 'user-trades',
    USER_OTC: 'user-otc',
    USER_COIN_TRANSACTIONS: 'user-coin-transactions',
    USER_BANK_TRANSACTIONS: 'user-bank-transactions',
    MULTI_SIGNATURE: 'multi-signature',
    BANK_ACCOUNTS: 'bank-accounts',
    ADDRESSES: 'addresses',
    TRANSACTIONS: 'transactions',
    REPOSITORIES: 'repositories'
}

export const filtersIntitialState = () => {
    const keys = Object.keys(CacheKeys)
    const initial = {}
    keys.forEach(key => {
        const value = CacheKeys[key]
        initial[value] = {}
    })
    return initial
}


export const paginationInitialState = () => {
    const keys = Object.keys(CacheKeys)
    const initial = {}
    keys.forEach(key => {
        const value = CacheKeys[key]
        initial[value] = {page: 1, limit: 10}
    })
    return initial
}

const verifyOptions = ['verified', 'not-verified']
const activeOptions = ['isActive', 'not-active']
const withdrawStatusOptions = ['created', 'pending', 'done', 'deleted']
const transactionFlowOptions = ['deposit', 'withdraw']
const paymentFlowOptions = ['indirect', 'direct']
const statusOptions = ['success', 'error', 'pending']
const actionOptions = ['maker', 'taker', 'buy', 'sell']
const causeOptions = ['deposit', 'withdraw', 'order', 'otc', 'admin']
const tokenStatusOptions = ['is-active', 'not-active']
const multiTypeOptions = ['INCREASE', 'DECREASE']
const multiStatusOptions = ['pending', 'done', 'rejected']
const fileTypeOptions = ['user', 'banking', 'coining', 'trade', 'order']
const userTypeFilterOptions = ['individual', 'business']
const userRoleFilterOptions = ['user', 'admin']


export const userFilterOptions = [
    { name: '_id', search: '_id'},
    { name: 'email', search: 'email' },
    { name: 'code', search: 'code'},
    { name: 'user-type', search: 'type', options: userTypeFilterOptions},
    { name: 'user-role', search: 'role', options: userRoleFilterOptions},
    { name: 'is-active', search: 'isActive', options: activeOptions },
]


export const workspaceFilterOptions = [
    { name: '_id', search: '_id'},
    { name: 'name', search: 'name'},
    { name: 'ip', search: 'ip' },
    { name: 'category', search: 'category' },
    { name: 'is-active', search: 'isActive', options: activeOptions },
    { name: 'date', search: 'date' },
]

export const withdrawFilterOptions = [
    { name: '_id', search: '_id'},
    { name: 'currency', search: 'data.currency'},
    { name: 'withdraw-status', search: 'status', options: withdrawStatusOptions},
    { name: 'date', search: 'date' }
]

export const contractFilterOptions = [
    {name: '_id', search: '_id'},
    {name: 'address', search: 'address'},
    {name: 'currency', search: 'currency'},
    {name: 'network', search: 'network'},
    { name: 'transaction-type', search: 'flow', options: transactionFlowOptions },
    {name: 'date', search: 'date'},
]

export const transactionFilterOptions = [
    {name: '_id', search: '_id'},
    {name: 'fromAddress', search: 'fromAddress'},
    {name: 'toAddress', search: 'toAddress'},
    {name: 'currency', search: 'currency'},
    {name: 'network', search: 'network'},
    {name: 'date', search: 'date'},
]

export const repositoryFilterOptions = [
    {name: '_id', search: '_id'},
    {name: 'address', search: 'address'},
    {name: 'currency', search: 'currency'},
    {name: 'network', search: 'network'},
    {name: 'date', search: 'date'},
]

export const paymentFilterOptions = [
    {name: '_id', search: '_id'},
    {name: 'address', search: 'address'},
    {name: 'currency', search: 'currency'},
    {name: 'network', search: 'network'},
    { name: 'payment-type', search: 'flow', options: paymentFlowOptions },
    {name: 'date', search: 'date'},
]


export const coinTraceFilterOptions = [
    { name: '_id', search: '_id'},
    { name: 'coin', search: 'currency' },
    { name: 'date', search: 'date' },
    { name: 'cause', search: 'cause.label', options: causeOptions},
    { name: 'action', search: 'cause.action', options: actionOptions},
]

export const affiliateTraceFilterOptions = [
    { name: '_id', search: '_id'},
    { name: 'coin', search: 'currency' },
    { name: 'date', search: 'date' },
    { name: 'cause', search: 'cause.label', options: causeOptions},
]


export const affiliateListFilterOptions = [
    { name: '_id', search: '_id'},
    { name: 'date', search: 'date' },
    { name: 'affiliate-code', search: 'code'}
]

export const tokenFilterOptions = [
    { name: 'token', search: 'symbol' },
    { name: 'smart-contract', search: 'contract' },
    { name: 'blockchain', search: 'name' },
    { name: 'network', search: 'network' },
    { name: 'token-status', search: 'isActive', options: tokenStatusOptions }
]


export const roleFilterOptions = [
    { name: 'role-name', search: 'name'},
    { name: 'date', search: 'date' }
]


export const fileFilterOptions = [
    { name: 'file-name', search: 'name' },
    { name: 'date', search: 'date'},
    { name: 'file-type', search: 'type', options: fileTypeOptions},
    { name: 'file-status', search: 'status', options: statusOptions}
]

export const balanceMultiSignatureFilterOptions = [
    { name: '_id', search: '_id'},
    { name: 'coin', search: 'params.currency' },
    { name: 'multi-type', search: 'params.action', options: multiTypeOptions},
    { name: 'multi-status', search: 'status', options: multiStatusOptions},
    { name: 'date', search: 'date' }
]

export const bankAccountFilterOptions = [
    {name: 'name', search: 'name'},
    {name: 'verifyAt', search: 'verifyAt', options: verifyOptions}
]

export const addressFilterOptions = [
    {name: 'name', search: 'name'},
    { name: 'currency', search: 'currency' },
    { name: 'network', search: 'network' },
    { name: 'address', search: 'address' },
    { name: 'memo', search: 'memo' },
    { name: 'date', search: 'date' }
]

export const workspaceSortOptions = [
    { title: 'date', sort: 'createdAt' }
]

export const contractSortOptions = [
    { title: 'date', sort: 'createdAt' }
]

export const transactionSortOptions = [
    { title: 'date', sort: 'createdAt' }
]

export const repositorySortOptions = [
    { title: 'date', sort: 'createdAt' }
]

export const coinTraceSortOptions = [
    { title: 'date', sort: 'createdAt' }
]

export const paymentSortOptions = [
    { title: 'dueDate', sort: 'dueDate' },
    { title: 'date', sort: 'createdAt' },
]


export const userSortOptions = [
    {title: 'balance', sort: 'balance' }
]

export const cartableSortOptions = [
    { title: 'date', sort: 'createdAt' }
]

export const fileSortOptions = [
    { title: 'date', sort: 'createdAt' }
]

export const coinTransactionSortOptions = [
    { title: 'amount', sort: 'amount' },
    { title: 'date', sort: 'createdAt' }
]

export const affiliateListSortOptions = [
    { title: 'use-count', sort: 'used' },
    { title: 'max-use', sort: 'maxUse' },
    { title: 'date', sort: 'createdAt' }
]

export const addressSortOptions = [
    { title: 'date', sort: 'createdAt' }
]

export const roleSortOptions = [
    { title: 'name', sort: 'name' },
    { title: 'date', sort: 'createdAt' }
]

export const tokenSortOptions = [
    { title: 'blockchain', sort: 'name' },
    { title: 'decimal', sort: 'decimal' }
]



