import TableLayout from "../../../layout/main/TableLayout";
import { Column, DText, Flex, Relative, Row } from "../../../../styles/CommonStyles";
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex";
import { SOCKET_URL } from "../../../../core/constants/urls";
import { TradeTypeBadge } from "../../../../styles/main/orders";
import { BiCheck } from "react-icons/bi";
import { RiCloseLine } from "react-icons/ri";
import Operation from "../../../common/utils/Operation";
import {
    availableTokenOperations,
    notAvailableTokenOperations
} from "../../../../core/constants/operation";
import useTokens from "../../../../core/hooks/main/setting/tokens/useTokens";
import ModalLayout from "../../../layout/main/ModalLayout";
import BasicModal from "../../../modals/BasicModal";
import { tokenAttributes } from "../../../../core/constants/headers";


const TokensTable = ({
    data
}) => {

    const { data: tokens } = data
    const { headers, cs } = tokenAttributes
    const { getTableIndex } = useGetTableIndex()

    const {
        tokenList,
        onDetailsClicked,
        onOptionClicked,
        deleteModal,
        onDeleteModalClose,
        onDeleteToken,
        deletingToken,
        deleteFromACModal,
        onACModalClose,
        onDeleteFromAC,
        acLoading
    } = useTokens(tokens)


    return (
        <TableLayout
            data={data}
            headers={headers}
            cs={cs}
            hasWriteAccess
        >
            {tokenList.map((token, idx) => (
                <Relative key={token._id}>
                    <Row cs={cs} index={idx}>
                        <Column>
                            {getTableIndex(idx)}
                        </Column>
                        <Column>
                            <Flex>
                                <img
                                    src={SOCKET_URL + token.icon}
                                    width='28px'
                                    alt={' '}
                                    style={{ margin: '0 5px' }}
                                />
                                <DText main style={{ margin: '0 5px' }} fontSize={'s'}>
                                    {token.currency.toUpperCase()}
                                </DText>
                            </Flex>
                        </Column>
                        <Column>
                            <TradeTypeBadge>
                                {token.currency}
                            </TradeTypeBadge>
                        </Column>
                        <Column>
                            {token.network}
                        </Column>
                        <Column>
                            {token.decimal}
                        </Column>
                        <Column center>
                            {token.isActive ?
                                <BiCheck color={'#37b88b'} size={24} />
                                :
                                <RiCloseLine color={'#f6465d'} size={24} />
                            }
                        </Column>
                        <Column center>
                            {token.isAvailable ?
                                <BiCheck color={'#37b88b'} size={24} />
                                :
                                <RiCloseLine color={'#f6465d'} size={24} />
                            }
                        </Column>

                    </Row>
                    <Column center operation>
                        <Operation
                            options={token.isAvailable ? availableTokenOperations : notAvailableTokenOperations}
                            onOptionClicked={(idx) => onOptionClicked(idx, token)}
                            onDetailsClicked={() => onDetailsClicked(token)}
                            hasWriteAccess
                            hasDetails
                        />
                    </Column>
                </Relative>
            ))}
            <ModalLayout
                onClose={onDeleteModalClose}
                open={deleteModal.open}
                width={'520px'}
            >
                <BasicModal
                    onClose={onDeleteModalClose}
                    _id={deleteModal.token?._id}
                    loading={deletingToken}
                    head={'delete-token-head'}
                    desc={'delete-token-desc'}
                    onSubmit={onDeleteToken}
                />
            </ModalLayout>

            <ModalLayout
                onClose={onACModalClose}
                open={deleteFromACModal.open}
                width={'520px'}
            >
                <BasicModal
                    onClose={onACModalClose}
                    _id={deleteFromACModal.token?._id}
                    loading={acLoading}
                    head={'delete-available-coin-head'}
                    desc={'delete-available-coin-desc'}
                    onSubmit={onDeleteFromAC}
                />
            </ModalLayout>
        </TableLayout>
    );
};




export default TokensTable;
