import ListLayout from "../../../components/layout/main/ListLayout";
import {PageCard} from "../../../styles/CommonStyles";
import {useState} from "react";
import Tabbar from "../../../components/common/tabs/Tabbar";
import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {MOBILE_SIZE} from "../../../core/constants/common";
import GeneralSettings from "../../../components/main/setting/general/general/GeneralSettings";
import RecoverPayment from "../../../components/main/setting/general/recover-payment/RecoverPayment";
import BlockedStatus from "../../../components/main/setting/general/blocked-status/BlockedStatus";


const TABS = ['recover-payment', 'blocked-status']
const RESP_TABS = [...TABS, 'overall']

const General = () => {

    const { width } = useWindowSize()

    /**
     * tabs => 1) dollar - 2) available banks - 3) affiliates 4) block access 5) move-to-master 6) general
     */
    const [activeTab, setActiveTab] = useState(1)

    const onTabChange = (idx) => {
        setActiveTab(idx)
    }

    const comps = [
        RecoverPayment, BlockedStatus, GeneralSettings
    ]
    const ActiveComp = comps[activeTab - 1]

    return (
        <ListLayout>
            <PageCard>
                <Tabbar
                    tabs={width > MOBILE_SIZE ? TABS : RESP_TABS}
                    active={activeTab}
                    onTabClicked={onTabChange}
                />
                <ActiveComp />
            </PageCard>
        </ListLayout>
    )
}

export default General;
