import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {useState} from "react";
import {TABLET_SIZE} from "../../../core/constants/common";
import ListLayout from "../../../components/layout/main/ListLayout";
import {PageCard} from "../../../styles/CommonStyles";
import FilterLayout from "../../../components/layout/filter/FilterLayout";
import {
    CacheKeys,
    repositoryFilterOptions, repositorySortOptions,
} from "../../../core/constants/filter";
import RepositoriesTable from "../../../components/main/reports/repositories/RepositoriesTable";
import RRepositoriesTable from "../../../components/responsive/main/reports/repositories/RRepositoriesTable";
import {useRepositoriesList} from "../../../core/services/react-query/report/repositories";


const Repositories = () => {
    const { width } = useWindowSize()

    const [repositories, setRepositories] = useState({loading: true, data: null})
    const onUserQuery = res => setRepositories(res)

    const Component = width > TABLET_SIZE ? RepositoriesTable : RRepositoriesTable

    return (
        <ListLayout>
            <PageCard>
                <FilterLayout
                    query={useRepositoriesList}
                    options={repositoryFilterOptions}
                    onQuerySuccess={onUserQuery}
                    cache={CacheKeys.REPOSITORIES}
                    sortOptions={repositorySortOptions}
                >
                    <Component data={repositories} />
                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}

export default Repositories
