import {useNavigate} from "react-router-dom";
import {businessContractAttributes, contractAttributes} from "../../../../core/constants/headers";
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex";
import TableLayout from "../../../layout/main/TableLayout";
import {Column, DText, Flex, Relative, Row} from "../../../../styles/CommonStyles";
import BooleanIcon from "../../../common/utils/BooleanIcon";
import DateTime from "../../../common/utils/DateTime";
import Operation from "../../../common/utils/Operation";
import NameTag from "../../../common/utils/NameTag";
import UserTag from "../../../common/utils/UserTag";
import CoinRow from "../../../common/common/CoinRow";
import {formatNumber} from "../../../../core/utils/common";
import {TradeTypeBadge} from "../../../../styles/main/orders";
import {CopyIcon, ShowIcon} from "../../../../styles/main/reports";
import Tooltip from "../../../common/utils/Tooltip";
import {useQueryContext} from "../../../../core/contexts/query";
import {useCopyToClipboard} from "../../../../core/hooks/common/useCopyToClipboard";
import Text from "../../../../core/utils/Text";
import Address from "../../../common/common/Address";


const ContractTable = ({ data, business }) => {

    const navigate = useNavigate()
    const { data: contracts } = data
    const { headers, cs } = business ? businessContractAttributes : contractAttributes
    const { getTableIndex } = useGetTableIndex()

    const { copyToClip } = useCopyToClipboard()

    const onDetailsClicked = (p) => {
        navigate(`/reports/wallet-transactions/${p._id}`)
    }

    return (
        <TableLayout
            data={data}
            cs={cs}
            headers={headers}
        >
                {contracts?.data?.map((item, idx) => (
                    <Relative key={item._id}>
                        <Row index={idx} cs={cs}>
                            <Column>
                                {getTableIndex(idx)}
                            </Column>
                            {!business ?
                                <Column>
                                    <UserTag user={item.user} idx={idx} />
                                </Column>
                            :null}
                            <Column>
                                <Flex>
                                    <Address address={item.address} />
                                    <Tooltip content={item.address}>
                                        <div>
                                            <ShowIcon size={20} />
                                        </div>
                                    </Tooltip>
                                    <Tooltip content={'copy-address'}>
                                        <div onClick={() => copyToClip(item.address)}>
                                            <CopyIcon size={20} />
                                        </div>
                                    </Tooltip>
                                </Flex>
                            </Column>
                            <Column>
                                <CoinRow coin={{ currency: item.currency }} />
                            </Column>
                            <Column>
                                {formatNumber(item.amount, { type: item.currency })}
                            </Column>
                            <Column>
                                <TradeTypeBadge>
                                    {item.network}
                                </TradeTypeBadge>
                            </Column>
                            <Column>
                                <TradeTypeBadge>
                                    <Text tid={item.flow} />
                                </TradeTypeBadge>
                            </Column>
                            {business ?
                                <Column center>
                                    <TradeTypeBadge>
                                        <Text tid={item?.blockchain?.status} />
                                    </TradeTypeBadge>
                                </Column>
                            : null}
                            <Column center>
                                <DateTime dt={item.createdAt} />
                            </Column>
                        </Row>
                        <Column operation>
                            <Operation
                                onDetailsClicked={() => onDetailsClicked(item)}
                                hasDetails
                            />
                        </Column>
                    </Relative>
            ))}
        </TableLayout>
    )
}

export default ContractTable
