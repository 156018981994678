import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {useState} from "react";
import {TABLET_SIZE} from "../../../core/constants/common";
import RContractTable from "../../../components/responsive/main/reports/contract/RContractTable";
import ContractTable from "../../../components/main/reports/contract/ContractTable";
import FilterLayout from "../../../components/layout/filter/FilterLayout";
import {CacheKeys, contractFilterOptions, contractSortOptions} from "../../../core/constants/filter";
import {useListWalletTransactions} from "../../../core/services/react-query/report/contract";
import {useParams} from "react-router-dom";
import {UserDetailListWrapper} from "../../../styles/main/user";



const WalletTransactions = () => {

    const params = useParams()
    const { width } = useWindowSize()

    const [contracts, setContracts] = useState({loading: true, data: null})
    const onUserQuery = res => setContracts(res)

    const Component = width > TABLET_SIZE ? ContractTable : RContractTable

    return (
        <UserDetailListWrapper>
            <FilterLayout
                query={useListWalletTransactions}
                options={contractFilterOptions}
                onQuerySuccess={onUserQuery}
                cache={CacheKeys.WALLET_TRANSACTIONS}
                extra={{ userId: params.id }}
                sortOptions={contractSortOptions}
            >
                <Component data={contracts} />
            </FilterLayout>
        </UserDetailListWrapper>
    )
}

export default WalletTransactions
