import {DText, Flex} from "../../../../styles/CommonStyles";
import {SOCKET_URL} from "../../../../core/constants/urls";
import {formatNumber} from "../../../../core/utils/common";
import styled from "styled-components";


const RepoBalanceView = ({ balance }) => {



    return (
        <Flex fw wrap justify={'flex-start'}>
            {balance?.map(b => {

                return (
                    <Flex>
                        <img
                            src={SOCKET_URL + `icons/${b?.currency}.png`}
                            width='20px'
                            alt={' '}
                        />
                        <DText main fontSize={'s'} margin={'0 5px'}>
                            {b.currency?.toUpperCase()} :
                        </DText>
                        <DText primary>
                            {formatNumber(b.amount, { type: b.currency })}
                        </DText>
                        <HLine />
                    </Flex>
                )
            })}
        </Flex>
    )
}

const HLine = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${props => props.theme.color}20;
  margin: 0 8px;
`

export default RepoBalanceView
